import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ActionPointPage} from './action-point.page';

const routes: Routes = [
  {
    path: '',
    component: ActionPointPage,
  },
  {
    path: 'create-action-point/:type/:id/:companyTeamId',
    loadChildren: () => import('../action-point/create-action-point/create-action-point.module').then(m => m.CreateActionPointPageModule)
  },
  {
    path: 'edit-action-point/:id',
    loadChildren: () => import('./edit-action-point/edit-action-point.module').then(m => m.EditActionPointPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActionPointPageRoutingModule {
}
