import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {
  getActionPointList,
  getAPListByMetric,
  getFeeds,
  getGlobalStatistics,
  getMomentsGallery,
  getSearch,
  isNewPost
} from '../variables';

import {GlobalService} from '../global/global.service';
import {ApiResponse, handleError} from '../utils';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FeedService {

  constructor(public globalService: GlobalService,
              public auth: AuthService,
              public http: HttpClient) {
  }

  apiGetFeeds(page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getFeeds + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  getActionPointList(page, type): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getActionPointList + '/' + type + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGlobalStatistics(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getGlobalStatistics, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiHomeSearch(type, query, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getSearch + '/' + type + '/' + query + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiIsNewPost(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(isNewPost + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetAPListByMetric(id, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getAPListByMetric + '/' + id + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetMomentsGallery(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getMomentsGallery + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

}
