import {environment} from '../../environments/environment';

const apiVersion: string = '/api/v1.5';
const apiVersionPublic: string = '/api/v1.5/public';
//auth
export const loginUrl: string = environment.baseUrl + apiVersion + '/login';
export const registerUrl: string = environment.baseUrl + apiVersion + '/register';
export const forgotPasswordUrl: string = environment.baseUrl + apiVersion + '/forgot-password';
export const logoutUrl: string = environment.baseUrl + apiVersion + '/logout';
export const verifyEmail: string = environment.baseUrl + apiVersion + '/verify_email';
export const verifyUsername: string = environment.baseUrl + apiVersion + '/verify_username';

//post
export const addStorePost: string = environment.baseUrl + apiVersion + '/post/store_post';
export const updatePost: string = environment.baseUrl + apiVersion + '/post/update_post';
export const addStoreActionPoint: string = environment.baseUrl + apiVersion + '/post/store_action_point';
export const updateActionPoint: string = environment.baseUrl + apiVersion + '/post/update_action_point';
export const getSinglePost: string = environment.baseUrl + apiVersion + '/post/view_post';
export const getSingleActionPoint: string = environment.baseUrl + apiVersion + '/post/view_action_point';
export const addCommentPost: string = environment.baseUrl + apiVersion + '/post/add_comment';
export const editCommentPost: string = environment.baseUrl + apiVersion + '/post/edit_comment';
export const getCommentList: string = environment.baseUrl + apiVersion + '/post/comments_list';
export const likeActionPoint: string = environment.baseUrl + apiVersion + '/post/like_action';
export const addRating: string = environment.baseUrl + apiVersion + '/post/add_star';
export const addAdoptActionPoint: string = environment.baseUrl + apiVersion + '/post/adopt_action_point';
export const viewPostLink: string = environment.baseUrl + apiVersion + '/post/view_post_link';
export const viewLikedBy: string = environment.baseUrl + apiVersion + '/post/view_interaction_by';
export const searchTag: string = environment.baseUrl + apiVersion + '/post/search_tag';
export const storePostMeta: string = environment.baseUrl + apiVersion + '/post/store_post_meta';
export const reportPost: string = environment.baseUrl + apiVersion + '/post/report_post';
export const hidePost: string = environment.baseUrl + apiVersion + '/post/hide_post';
export const likeActionComment: string = environment.baseUrl + apiVersion + '/post/like_action_comment';
export const viewRatingStatistics: string = environment.baseUrl + apiVersion + '/post/view_rating_statistics';
export const translatePost: string = environment.baseUrl + apiVersion + '/post/translate_post';
export const translateComment: string = environment.baseUrl + apiVersion + '/post/translate_comment';
export const removeAdoptActionPoint: string = environment.baseUrl + apiVersion + '/post/remove_adopt_action_point';
export const viewAPCollection: string = environment.baseUrl + apiVersion + '/post/view_action_point_collection';
export const apCollectionList: string = environment.baseUrl + apiVersion + '/post/action_point_collection_list';
export const deletePost: string = environment.baseUrl + apiVersion + '/post/delete_post';
export const createMoment: string = environment.baseUrl + apiVersion + '/post/store_moment';
export const setMood: string = environment.baseUrl + apiVersion + '/post/set_mood';
export const getPostMeta: string = environment.baseUrl + apiVersion + '/post/get_post_meta';
export const hideComment: string = environment.baseUrl + apiVersion + '/post/hide_comment';
export const reportComment: string = environment.baseUrl + apiVersion + '/post/report_comment';
export const qrCode: string = environment.baseUrl + apiVersion + '/qr';

//user
export const changePassword: string = environment.baseUrl + apiVersion + '/user/update_password';
export const getUserProfile: string = environment.baseUrl + apiVersion + '/user/my_profile';
export const removeProfilePic: string = environment.baseUrl + apiVersion + '/user/remove_profile_image';
export const removeTitlePic: string = environment.baseUrl + apiVersion + '/user/remove_title_image';
export const removeUser: string = environment.baseUrl + apiVersion + '/user/delete_user';
export const updateUserProfile: string = environment.baseUrl + apiVersion + '/user/update_profile';
export const getOtherUserProfile: string = environment.baseUrl + apiVersion + '/user/user_profile';
export const getUserActionPoints: string = environment.baseUrl + apiVersion + '/user/action_point_list';
export const getUserTeamAP: string = environment.baseUrl + apiVersion + '/user/created_and_adopted_action_point_list';
export const getUserPosts: string = environment.baseUrl + apiVersion + '/user/post_list';
export const getuserFollowerList: string = environment.baseUrl + apiVersion + '/user/follower_list';
export const getuserFollowingList: string = environment.baseUrl + apiVersion + '/user/following_list';
export const userUnfollow: string = environment.baseUrl + apiVersion + '/user/unfollow';
export const userfollow: string = environment.baseUrl + apiVersion + '/user/follow';
export const userTeamList: string = environment.baseUrl + apiVersion + '/user/team_list';
export const getGeneralOptions: string = environment.baseUrl + apiVersion + '/get_options';
export const getUserSuggestions: string = environment.baseUrl + apiVersion + '/user/suggestions';
export const getAllUsersList: string = environment.baseUrl + apiVersion + '/user/all_users_list';
export const getTeamListAsAdmin: string = environment.baseUrl + apiVersion + '/user/team_list';
export const getAllTutorials: string = environment.baseUrl + apiVersion + '/tutorial/get_all_tutorials';
export const getAdminCompanies: string = environment.baseUrl + apiVersion + '/user/admin_companies';
export const blockUser: string = environment.baseUrl + apiVersion + '/user/block_user';
export const reportUser: string = environment.baseUrl + apiVersion + '/user/report_user';
export const companiesList: string = environment.baseUrl + apiVersion + '/user/companies_list';
export const userCompaniesList: string = environment.baseUrl + apiVersion + '/user/companies_list';
export const userCompaniesFollows: string = environment.baseUrl + apiVersion + '/user/followed_companies';
export const apListForCompany: string = environment.baseUrl + apiVersion + '/user/action_point_list_for_company';
export const companiesMemberTeams: string = environment.baseUrl + apiVersion + '/user/companies_member_teams';
export const apListForTeam: string = environment.baseUrl + apiVersion + '/user/action_point_list_for_team';
export const adminCompaniesNoPagination: string = environment.baseUrl + apiVersion + '/user/admin_companies_no_pagination';


//feeds
export const getFeeds: string = environment.baseUrl + apiVersion + '/feed';
export const getActionPointList: string = environment.baseUrl + apiVersion + '/feed/action_point_list';
export const getGlobalStatistics: string = environment.baseUrl + apiVersion + '/global_statistics';
export const getSearch: string = environment.baseUrl + apiVersion + '/search';
export const isNewPost: string = environment.baseUrl + apiVersion + '/feed/is_new_post';
export const getAPListByMetric: string = environment.baseUrl + apiVersion + '/feed/action_point_list_by_metric';
export const getMomentsGallery: string = environment.baseUrl + apiVersion + '/feed/moment_feed';

//team
export const viewTeam: string = environment.baseUrl + apiVersion + '/team/view_team';
export const editTeam: string = environment.baseUrl + apiVersion + '/team/edit_team';
export const joinTeam: string = environment.baseUrl + apiVersion + '/team/join_team';
export const leaveTeam: string = environment.baseUrl + apiVersion + '/team/leave_team';
export const getTeamPost: string = environment.baseUrl + apiVersion + '/team/team_posts';
export const getTeamFeeds: string = environment.baseUrl + apiVersion + '/team/team_feed';
export const getTeamMembers: string = environment.baseUrl + apiVersion + '/team/team_members';
export const getAllTeamsList: string = environment.baseUrl + apiVersion + '/team/all_teams_list';
export const addTeamAdmin: string = environment.baseUrl + apiVersion + '/team/add_admin';
export const removeTeamAdmin: string = environment.baseUrl + apiVersion + '/team/remove_admin';
export const removeTeamTitleImage: string = environment.baseUrl + apiVersion + '/team/remove_title_image';
export const removeTeamProfilePic: string = environment.baseUrl + apiVersion + '/team/remove_profile_image';
export const addTeam: string = environment.baseUrl + apiVersion + '/team/create_team';
export const viewAllJoinRequests: string = environment.baseUrl + apiVersion + '/team/view_all_join_requests_list';
export const acceptJoinRequest: string = environment.baseUrl + apiVersion + '/team/accept_join_request';
export const declineJoinRequest: string = environment.baseUrl + apiVersion + '/team/decline_join_request';
export const removeTeamMember: string = environment.baseUrl + apiVersion + '/team/remove_member';
export const usersToInvite: string = environment.baseUrl + apiVersion + '/team/users_to_invite';
export const inviteMember: string = environment.baseUrl + apiVersion + '/team/invite_member';
export const assignActionPoint: string = environment.baseUrl + apiVersion + '/team/assign_action_point';
export const removeActionPoint: string = environment.baseUrl + apiVersion + '/team/remove_action_point';
export const viewJoinRequestsList: string = environment.baseUrl + apiVersion + '/team/view_join_requests_list';
export const viewInvitationList: string = environment.baseUrl + apiVersion + '/team/view_invitation_list';
export const acceptInvitation: string = environment.baseUrl + apiVersion + '/team/accept_invitation';
export const rejectInvitation: string = environment.baseUrl + apiVersion + '/team/reject_invitation';
export const teamFollow: string = environment.baseUrl + apiVersion + '/team/follow_team';
export const teamUnfollow: string = environment.baseUrl + apiVersion + '/team/unfollow_team';
export const apsAssignedByMembers = environment.baseUrl + apiVersion + '/team/team_assigned_action_points_list';
export const teamActionPoints = environment.baseUrl + apiVersion + '/team/team_action_points';

//stories
export const markSeen: string = environment.baseUrl + apiVersion + '/stories/mark_seen';
export const getStoryCategories: string = environment.baseUrl + apiVersion + '/stories/get_categories';
export const getStories: string = environment.baseUrl + apiVersion + '/stories/get_stories';

//notifications
export const getAllNotifications: string = environment.baseUrl + apiVersion + '/notification/get_all_notifications';
export const markSeenNotification: string = environment.baseUrl + apiVersion + '/notification/mark_seen';
export const markAllSeenNotification: string = environment.baseUrl + apiVersion + '/notification/mark_all_seen';
export const unreadNotification: string = environment.baseUrl + apiVersion + '/notification/unread_count';

//company
export const createCompany: string = environment.baseUrl + apiVersion + '/company/create_company';
export const updateCompany: string = environment.baseUrl + apiVersion + '/company/update_company';
export const getCompaniesList: string = environment.baseUrl + apiVersion + '/company/all_companies_list';
export const viewCompany: string = environment.baseUrl + apiVersion + '/company/view_company';
export const followCompany: string = environment.baseUrl + apiVersion + '/company/follow_company';
export const unfollowCompany: string = environment.baseUrl + apiVersion + '/company/unfollow_company';
export const companyActionPointsList: string = environment.baseUrl + apiVersion + '/company/company_action_points_list';
export const companyPostsList: string = environment.baseUrl + apiVersion + '/company/company_posts_list';
export const companySurveysList: string = environment.baseUrl + apiVersion + '/company/company_surveys_list';
export const companyFollowerList: string = environment.baseUrl + apiVersion + '/company/company_follower_list';
export const companyTeamsList: string = environment.baseUrl + apiVersion + '/company/company_teams_list';
export const companyEmployeeList: string = environment.baseUrl + apiVersion + '/company/company_employee_list';
export const assignCompanyActionPoint: string = environment.baseUrl + apiVersion + '/company/assign_action_point';
export const removeCompanyActionPoint: string = environment.baseUrl + apiVersion + '/company/remove_action_point';
export const removeCompanyEmployee: string = environment.baseUrl + apiVersion + '/company/remove_employee';
export const addCompanyAdmin: string = environment.baseUrl + apiVersion + '/company/add_admin';
export const removeCompanyAdmin: string = environment.baseUrl + apiVersion + '/company/remove_admin';
export const removeCompanyMember: string = environment.baseUrl + apiVersion + '/company/remove_employee';
export const removeCompanyProfileImg: string = environment.baseUrl + apiVersion + '/company/remove_profile_image';
export const removeCompanyTitleImg: string = environment.baseUrl + apiVersion + '/company/remove_title_image';
export const companyAssignedAPList: string = environment.baseUrl + apiVersion + '/company/company_assigned_action_points_list';

//search
export const searchTeams: string = environment.baseUrl + apiVersion + '/search/teams';
export const searchCompanies: string = environment.baseUrl + apiVersion + '/search/companies';


//metrics
export const getMetrics: string = environment.baseUrl + apiVersion + '/metric/get_metrics';


//anonymous API requests
export const getSinglePostAnonymous: string = environment.baseUrl + apiVersionPublic + '/post/view_post';
export const getSingleActionPointAnonymous: string = environment.baseUrl + apiVersionPublic + '/post/view_action_point';
export const viewRatingStatisticsAnonymous: string = environment.baseUrl + apiVersionPublic + '/post/view_rating_statistics';
export const likeActionPointAnonymous: string = environment.baseUrl + apiVersionPublic + '/post/like_action';
export const addAdoptActionPointAnonymous: string = environment.baseUrl + apiVersionPublic + '/post/adopt_action_point';
export const removeAdoptActionPointAnonymous: string = environment.baseUrl + apiVersionPublic + '/post/remove_adopt_action_point';
export const addRatingAnonymous: string = environment.baseUrl + apiVersionPublic + '/post/add_star';
export const getCommentListAnonymous: string = environment.baseUrl + apiVersionPublic + '/post/comments_list';
export const getSharedPost: string = environment.baseUrl + apiVersionPublic + '/post/view_shared_post';
export const postTrackPost: string = environment.baseUrl + apiVersionPublic + '/post/track_share';


// Share
export const getShareLink: string = environment.baseUrl + apiVersionPublic + '/post/share';
