import { Component, OnInit } from '@angular/core';
import {NavController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-login-side',
  templateUrl: './login-side.component.html',
  styleUrls: ['./login-side.component.scss'],
})
export class LoginSideComponent  implements OnInit {

  constructor(
    private navController: NavController,
  ) { }

  ngOnInit() {
  }

  async navigateTo(routeToNav: string){
    await this.navController.navigateForward([routeToNav]);
  }
}
