// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-comp {
  margin-left: auto;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--planeed-color-white);
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .profile-comp {
    margin-right: 20px;
    margin-top: 20px;
    width: 300px;
  }
}
@media only screen and (min-width: 1920px) and (max-width: 2560px) {
  .profile-comp {
    margin-right: 30px;
    margin-top: 30px;
    width: 400px;
  }
}
@media only screen and (min-width: 2560px) {
  .profile-comp {
    margin-right: 30px;
    margin-top: 30px;
    width: 500px;
  }
}
.profile-comp .profile-header-img {
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  cursor: pointer;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .profile-comp .profile-header-img {
    height: 100px;
  }
}
@media only screen and (min-width: 1920px) {
  .profile-comp .profile-header-img {
    height: 150px;
  }
}
.profile-comp .photo-box {
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  cursor: pointer;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .profile-comp .photo-box {
    height: 100px;
    margin-top: -75px;
  }
}
@media only screen and (min-width: 1920px) {
  .profile-comp .photo-box {
    height: 150px;
    margin-top: -112px;
  }
}
.profile-comp .photo-box img {
  border-radius: 50%;
  object-fit: cover;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .profile-comp .photo-box img {
    width: 100px;
    height: 100px;
  }
}
@media only screen and (min-width: 1920px) {
  .profile-comp .photo-box img {
    width: 150px;
    height: 150px;
  }
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .profile-comp .content {
    padding: 15px;
  }
}
@media only screen and (min-width: 1920px) {
  .profile-comp .content {
    padding: 20px;
  }
}
.profile-comp .content h2 {
  padding: 0;
  margin: 0;
  color: var(--ion-color-text-color);
  cursor: pointer;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .profile-comp .content h2 {
    font-size: 17px;
  }
}
@media only screen and (min-width: 1920px) {
  .profile-comp .content h2 {
    font-size: 22px;
  }
}
.profile-comp .content .additional-info {
  margin-top: 10px;
}
.profile-comp .content p {
  color: var(--ion-color-medium);
  margin: 0;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .profile-comp .content p {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1920px) {
  .profile-comp .content p {
    font-size: 14px;
  }
}
.profile-comp .user-name {
  color: var(--ion-color-text-color);
  font-weight: normal !important;
  margin-bottom: 5px !important;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .profile-comp .user-name {
    font-size: 12px !important;
  }
}
@media only screen and (min-width: 1920px) {
  .profile-comp .user-name {
    font-size: 14px !important;
  }
}
.profile-comp .work_at span {
  color: var(--ion-color-secondary-tint) !important;
  text-decoration: underline;
}
.profile-comp .work_at span:hover {
  cursor: pointer;
}
.profile-comp .impact-profile {
  color: var(--ion-color-warning);
  padding-top: 15px;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .profile-comp .impact-profile {
    font-size: 17px;
  }
}
@media only screen and (min-width: 1920px) {
  .profile-comp .impact-profile {
    font-size: 22px;
  }
}
.profile-comp .impact-profile ion-icon {
  margin-bottom: -2px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
