// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-teams-comp {
  margin-left: auto;
  margin-right: 20px;
  margin-top: 20px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--planeed-color-white);
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .my-teams-comp {
    margin-right: 20px;
    margin-top: 20px;
    width: 300px;
    padding: 15px;
  }
}
@media only screen and (min-width: 1920px) and (max-width: 2560px) {
  .my-teams-comp {
    margin-right: 30px;
    margin-top: 30px;
    width: 400px;
    padding: 20px;
  }
}
@media only screen and (min-width: 2560px) {
  .my-teams-comp {
    margin-right: 30px;
    margin-top: 30px;
    width: 500px;
    padding: 20px;
  }
}
.my-teams-comp .searchbarHeader {
  display: flex;
  align-items: center;
  padding-top: 0;
  height: 30px;
}
.my-teams-comp .searchbarHeader ion-label {
  color: var(--ion-color-tertiary);
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .my-teams-comp .searchbarHeader ion-label {
    padding-left: 10px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 1920px) {
  .my-teams-comp .searchbarHeader ion-label {
    padding-left: 15px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .my-teams-comp .searchbarHeader .custom-search-team-icon {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 1920px) {
  .my-teams-comp .searchbarHeader .custom-search-team-icon {
    width: 30px;
    height: 30px;
  }
}
.my-teams-comp .searchbarHeader .custom-search-box {
  position: absolute;
  right: 25px;
  width: 20px;
  height: 20px;
}
.my-teams-comp .centeredText {
  display: flex;
  justify-content: center;
}
.my-teams-comp .textNotFound {
  min-height: 40px;
  color: #888;
  text-align: center;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .my-teams-comp .textNotFound {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1920px) {
  .my-teams-comp .textNotFound {
    font-size: 14px;
  }
}
.my-teams-comp div.showMoreButton {
  display: flex;
  justify-content: center;
  /*margin-top: -10px;*/
  padding-top: 4px;
}
.my-teams-comp div.showMoreButton .load-more-TC-btn {
  color: var(--ion-color-secondary);
  text-decoration: underline;
  margin-top: 0;
  padding: 0;
  height: 20px;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .my-teams-comp div.showMoreButton .load-more-TC-btn {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1920px) {
  .my-teams-comp div.showMoreButton .load-more-TC-btn {
    font-size: 14px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
