import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {UserService} from 'src/app/services/user/user.service';
import {ApiResponse} from 'src/app/services/utils';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit {

  statisticsPopUp = 'Loading...';

  constructor(
    private modalController: ModalController,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.loadGeneralOptions();
  }

  public async closeModal(): Promise<void> {
    await this.modalController.dismiss(null, 'cancel');
  }


  loadGeneralOptions() {
    const fieldsen = {
      options: ['statisticsPopUp']
    };
    const fieldsde = {
      options: ['statisticsPopUpDe']
    };

    const lang = localStorage.getItem('selectLanguage') || 'de';

    if (lang === 'en') {
      this.userService.apiGeneralOptions(fieldsen).subscribe((result: ApiResponse) => {
        if (result.status) {
          result.data.forEach(d => {
            if (d.slug === 'statisticsPopUp') {
              this.statisticsPopUp = d.value;
            }

          });
        }
      });
    } else {
      this.userService.apiGeneralOptions(fieldsde).subscribe((result: ApiResponse) => {
        if (result.status) {
          result.data.forEach(d => {
            if (d.slug === 'statisticsPopUpDe') {
              this.statisticsPopUp = d.value;
            }
          });
        }
      });
    }
  }
}
