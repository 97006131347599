import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storageServ: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    this.storageServ = await this.storage.create();
  }


  public async setInteractionSound(key: string, val: string) {
    await this.storageServ.set(key, val);
  }

  public async getInteractionSound(key: string) {
    const interactionSoundStatus: any = await this.storageServ?.get(key);
    return Promise.resolve(interactionSoundStatus);
  }

  public async isInteractionSoundOn() {
    const interactionSoundStatus: any = await this.storageServ.get('interactionSound');
    if (interactionSoundStatus === 'true') {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }

  public async checkForCreatedStorage() {
    await this.init();
    const val = await this.getInteractionSound('interactionSound');
    if (val === null || val === undefined) {
      await this.setInteractionSound('interactionSound', 'true');
    }
  }
}
