// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --ion-background-color: var(--planeed-color-white);
}

.content {
  padding: 10px;
}
.content h3 {
  padding: 0;
  margin: 0;
  color: var(--ion-color-medium);
  font-size: 16px;
}

.post-content {
  background: var(--planeed-color-white);
  min-height: 300px;
}

ion-segment-button {
  font-size: 11px;
  --background-checked: var(--ion-color-secondary);
  --color-checked: var(--planeed-color-white);
  --ion-background-color: var(--ion-color-secondary);
  --indicator-color: transparent !important;
  background: var(--planeed-color-white);
}

.segment-button-checked {
  background: var(--ion-color-secondary);
  border-radius: 9px !important;
}

.swiper .swiper-slide {
  height: 600px !important;
  overflow: auto;
}

.impact_score_txt {
  color: var(--ion-color-warning);
}

.content-not-found {
  color: var(--ion-color-medium);
  font-size: 14px;
  padding: 20px;
  text-align: center;
}

.main-content {
  color: var(--ion-color-secondary);
}
.main-content span {
  font-size: 12px;
}

.alignment-center {
  display: flex;
  align-items: center;
}

.h2-center {
  text-align: center;
  color: var(--ion-color-secondary);
  font-size: 18px;
}
.h2-center ion-icon {
  position: absolute;
  margin-top: 1px;
  margin-left: -22px;
}

p {
  margin: 0 0;
}

.content-not-found {
  color: var(--ion-color-medium);
  font-size: 14px;
  padding: 20px;
  text-align: center;
}

.globle-icon {
  width: 13px;
  position: absolute;
  left: -6px;
  margin-top: 4px;
}

ion-segment-button {
  min-width: auto !important;
}

.help-icon {
  display: inline-block;
  background: var(--ion-color-secondary);
  color: var(--planeed-color-white);
  width: 20px;
  height: 20px;
  border-radius: 13px;
  font-size: 15px;
  padding-top: 2px;
  top: -2px;
  position: relative;
}

.modal-wrapper, .modal-shadow {
  background: transparent;
}

.p-users {
  padding-left: 20px;
  text-align: left;
  color: var(--ion-color-medium);
  margin: 0;
}

.centeredText {
  display: flex;
  justify-content: center;
}

.textNotFound {
  padding-top: 15px;
  min-height: 60px;
  color: #888;
  text-align: center;
  max-width: 80vw;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
