import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

import {LeafImpactScoreComponent} from './leaf-impact-score.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule],
  declarations: [LeafImpactScoreComponent],
  exports: [LeafImpactScoreComponent]
})
export class LeafImpactScoreModule {
}
