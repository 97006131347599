// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  width: 100%;
  align-items: center;
}
:host .type-toggle {
  padding-inline-start: 0.5rem;
  cursor: pointer;
}
:host .type-toggle .show-option,
:host .type-toggle .hide-option {
  font-size: 1.2rem;
  display: block;
}
:host .type-toggle .show-option:not(ion-icon),
:host .type-toggle .hide-option:not(ion-icon) {
  text-transform: uppercase;
  font-size: 1rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
