// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  text-align: center;
  width: 100%;
  padding: 0 15px;
  font-size: 14px;
}

h2 {
  width: 100%;
  text-align: center;
  padding: 0 15px;
}
h2 ion-icon {
  color: #1e2023;
  margin-bottom: -3px;
}

.social-media-buttons {
  padding: 10px 30px;
  font-size: 22px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--ion-color-success);
}
.social-media-buttons ion-icon {
  cursor: pointer;
}

.close-button {
  font-size: 14px;
  position: fixed;
  bottom: 20px;
  left: 5px;
  color: var(--ion-color-danger);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
