import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReadMoreComponent} from './read-more.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule, TranslateModule
  ],
  declarations: [ReadMoreComponent],
  exports: [ReadMoreComponent]
})
export class ReadMoreModule {
}
