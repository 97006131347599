import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {TeamListComponent} from './team-list.component';
import {ReadMoreModule} from '../read-more/read-more.module';

@NgModule({
  declarations: [TeamListComponent],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    ReadMoreModule
  ],
  exports: [
    TeamListComponent
  ]
})
export class TeamListModule {
}
