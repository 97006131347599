import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from 'src/app/services/auth/auth.service';
import {GlobalService} from 'src/app/services/global/global.service';
import {UserModel} from 'src/app/models/user.model';
import {IonContent, IonSegment} from '@ionic/angular';
import {SwiperComponent} from 'swiper/angular';
import {SwiperOptions} from 'swiper';
import {UserService} from 'src/app/services/user/user.service';
import {ApiResponse} from 'src/app/services/utils';
import {FeedService} from 'src/app/services/feed/feed.service';
import {PostModel} from '../../models/post.model';


@Component({
  selector: 'app-leaf',
  templateUrl: 'leaf.page.html',
  styleUrls: ['leaf.page.scss']
})
export class LeafPage implements OnInit {
  @ViewChild(IonContent, {static: false}) leafcontent: IonContent;
  @ViewChild('segment') segment: IonSegment;
  @ViewChild('swiper') swiper: SwiperComponent;
  errorMessage = '';

  user: UserModel;

  apISList: PostModel[];
  apCo2List: PostModel[];
  apEnergyList: PostModel[];
  apWaterList: PostModel[];
  apPlasticList: PostModel[];

  impactScoreListLoaded = false;
  co2ListLoaded = false;
  energyListLoaded = false;
  waterListLoaded = false;
  plasticListLoaded = false;

  pageISList = 1;
  pageCo2List = 1;
  pageEnergyList = 1;
  pageWaterList = 1;
  pagePlasticList = 1;
  pageUserList = 1;

  iSResponseFlag = false;
  co2ResponseFlag = false;
  energyResponseFlag = false;
  waterResponseFlag = false;
  plasticResponseFlag = false;


  config: SwiperOptions = {
    pagination: true,
    height: 400
  };

  activeSegment: string;
  loading = false;
  dataFirstLoad = true;
  globalStatistics = [];
  impactScore = {
    totalActionPoints: 0,
    totalImpactScore: '',
    totalUsers: 0,
    averageImpactScore: '',
    totalAdoptions: 0
  };
  statisticValue = {
    actionPoints: 0,
    metricImpact: '',
    adopts: 0,
    averageImpactScore: ''
  };
  co2Score = {
    metricId: 0,
    name: '',
    unit: '',
    icon: '',
    global: this.statisticValue,
    user: this.statisticValue
  };
  energyScore = {
    metricId: 0,
    name: '',
    unit: '',
    icon: '',
    global: this.statisticValue,
    user: this.statisticValue
  };
  waterScore = {
    metricId: 0,
    name: '',
    unit: '',
    icon: '',
    global: this.statisticValue,
    user: this.statisticValue
  };
  plasticScore = {
    metricId: 0,
    name: '',
    unit: '',
    icon: '',
    global: this.statisticValue,
    user: this.statisticValue
  };

  constructor(private globalService: GlobalService,
              private auth: AuthService,
              private feedService: FeedService,
              private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.globalService.homeScrollTop.subscribe(val => {
      if (val !== null && val === 'leaf') {
        this.leafcontent.scrollToTop();
        this.firstLoad();
      }
    });

    this.user = this.auth.user;
    this.firstLoad();
  }

  firstLoad() {
    this.pageCo2List = 1;
    this.pageISList = 1;
    this.pagePlasticList = 1;
    this.pageWaterList = 1;
    this.pageEnergyList = 1;

    this.loadUserData();
    this.loadGlobalStatistics();
  }

  doRefresh(event) {
    setTimeout(() => {
      this.firstLoad();
      this.loadUserData();
      event.target.complete();
    }, 1000);
  }

  loadUserData() {
    if(this.auth && this.auth.isAuthenticated){
      this.userService.apiUserProfile().subscribe((result: ApiResponse) => {
        if (result.status) {
          this.user.actionPointsCount = result.data.actionPointsCount;
          this.user.adoptedActionPointsCount = result.data.adoptedActionPointsCount;
          this.user.impactScore = result.data.impactScore;
          localStorage.setItem('user', JSON.stringify(this.user));
          this.auth.userLoggedIn.next(result.data.firstName);
        }
      });
    }
  }

  loadGlobalStatistics() {
    this.feedService.apiGlobalStatistics().subscribe((result: ApiResponse) => {
      if (result.status) {
        this.globalStatistics = result.data;
        this.impactScore = this.globalStatistics[0];
        this.loadImpactScoreList();

        this.co2Score = this.globalStatistics.find(el => el.key === 'co2');
        this.loadCo2List(this.co2Score.metricId);

        this.energyScore = this.globalStatistics.find(el => el.key === 'electricity');
        this.loadEnergyList(this.energyScore.metricId);

        this.waterScore = this.globalStatistics.find(el => el.key === 'water');
        this.loadWaterList(this.waterScore.metricId);

        this.plasticScore = this.globalStatistics.find(el => el.key === 'plastic');
        this.loadPlasticList(this.plasticScore.metricId);

        this.loading = false;
      } else {
        this.errorMessage = result.error[0];
      }
    });
  }

  loadImpactScoreList() {
    if (this.iSResponseFlag === false) {
      this.iSResponseFlag = true;
      this.feedService.apiGetAPListByMetric(0, this.pageISList).subscribe((result: ApiResponse) => {
        if (result.status) {
          if (this.pageISList === 1) {
            this.apISList = result.data.data;
          } else {
            result.data.data.forEach(post => {
              this.apISList.push(post);
            });
          }
          this.pageISList++;
          this.loading = false;
          if (this.apISList.length > 0) {
            this.impactScoreListLoaded = true;
          }
        } else {
          this.errorMessage = result.error[0];
        }
        this.iSResponseFlag = false;
        this.loading = false;
      });
    }
  }

  loadCo2List(id) {
    if (this.co2ResponseFlag === false) {
      this.co2ResponseFlag = true;
      this.feedService.apiGetAPListByMetric(id, this.pageCo2List).subscribe((result: ApiResponse) => {
        if (result.status) {
          if (this.pageCo2List === 1) {
            this.apCo2List = result.data.data;
          } else {
            result.data.data.forEach(post => {
              this.apCo2List.push(post);
            });
          }
          this.pageCo2List++;
          this.loading = false;
          if (this.apCo2List.length > 0) {
            this.co2ListLoaded = true;
          }
        } else {
          this.errorMessage = result.error[0];
        }
        this.co2ResponseFlag = false;
        this.loading = false;
      });
    }
  }

  loadEnergyList(id) {
    if (this.energyResponseFlag === false) {
      this.energyResponseFlag = true;
      this.feedService.apiGetAPListByMetric(id, this.pageEnergyList).subscribe((result: ApiResponse) => {
        if (result.status) {
          if (this.pageEnergyList === 1) {
            this.apEnergyList = result.data.data;
          } else {
            result.data.data.forEach(post => {
              this.apEnergyList.push(post);
            });
          }
          this.pageEnergyList++;
          this.loading = false;
          if (this.apEnergyList.length > 0) {
            this.energyListLoaded = true;
          }
        } else {
          this.errorMessage = result.error[0];
        }
        this.energyResponseFlag = false;
        this.loading = false;
      });
    }
  }

  loadWaterList(id) {
    if (this.waterResponseFlag === false) {
      this.waterResponseFlag = true;
      this.feedService.apiGetAPListByMetric(id, this.pageWaterList).subscribe((result: ApiResponse) => {
        if (result.status) {
          if (this.pageWaterList === 1) {
            this.apWaterList = result.data.data;
          } else {
            result.data.data.forEach(post => {
              this.apWaterList.push(post);
            });
          }
          this.pageWaterList++;
          this.loading = false;
          if (this.apWaterList.length > 0) {
            this.waterListLoaded = true;
          }
        } else {
          this.errorMessage = result.error[0];
        }
        this.waterResponseFlag = false;
        this.loading = false;
      });
    }
  }

  loadPlasticList(id) {
    if (this.plasticResponseFlag === false) {
      this.plasticResponseFlag = true;
      this.feedService.apiGetAPListByMetric(id, this.pagePlasticList).subscribe((result: ApiResponse) => {
        if (result.status) {
          if (this.pagePlasticList === 1) {
            this.apPlasticList = result.data.data;
          } else {
            result.data.data.forEach(post => {
              this.apPlasticList.push(post);
            });
          }
          this.pagePlasticList++;
          this.loading = false;
          if (this.apPlasticList.length > 0) {
            this.plasticListLoaded = true;
          }
        } else {
          this.errorMessage = result.error[0];
        }
        this.plasticResponseFlag = false;
        this.loading = false;
      });
    }
  }

  loadData(event) {
    this.loading = true;
    if (this.activeSegment === 'impactScore') {
      this.loadImpactScoreList();
    } else if (this.activeSegment === 'co2') {
      this.loadCo2List(this.co2Score.metricId);
    } else if (this.activeSegment === 'energy') {
      this.loadEnergyList(this.energyScore.metricId);
    } else if (this.activeSegment === 'water') {
      this.loadWaterList(this.waterScore.metricId);
    } else if (this.activeSegment === 'plastic') {
      this.loadPlasticList(this.plasticScore.metricId);
    }
    event.target.complete();
  }

  segmentChanged(event) {
    if (event.target.value === 'impactScore') {
      this.activeSegment = 'impactScore';
      this.swiper.swiperRef.slideTo(0);
    }
    if (event.target.value === 'co2') {
      this.activeSegment = 'co2';
      this.swiper.swiperRef.slideTo(1);
    }
    if (event.target.value === 'energy') {
      this.activeSegment = 'energy';
      this.swiper.swiperRef.slideTo(2);
    }
    if (event.target.value === 'water') {
      this.activeSegment = 'water';
      this.swiper.swiperRef.slideTo(3);
    }
    if (event.target.value === 'plastic') {
      this.activeSegment = 'plastic';
      this.swiper.swiperRef.slideTo(4);
    }
  }

  onSlideChange(event) {
    if (event[0].activeIndex === 0) {
      this.activeSegment = 'impactScore';
      this.segment.value = 'impactScore';
      this.loadImpactScoreList();
    }
    if (event[0].activeIndex === 1) {
      this.activeSegment = 'co2';
      this.segment.value = 'co2';
      this.loadCo2List(this.co2Score.metricId);
    }
    if (event[0].activeIndex === 2) {
      this.activeSegment = 'energy';
      this.segment.value = 'energy';
      this.loadEnergyList(this.energyScore.metricId);
    }
    if (event[0].activeIndex === 3) {
      this.activeSegment = 'water';
      this.segment.value = 'water';
      this.loadWaterList(this.waterScore.metricId);
    }
    if (event[0].activeIndex === 4) {
      this.activeSegment = 'plastic';
      this.segment.value = 'plastic';
      this.loadPlasticList(this.plasticScore.metricId);
    }
  }

}
