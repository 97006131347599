import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {
  addAdoptActionPoint,
  addCommentPost,
  addRating,
  addStoreActionPoint,
  addStorePost,
  apCollectionList,
  createMoment,
  deletePost,
  editCommentPost,
  getCommentList,
  getPostMeta,
  getSingleActionPoint,
  getSinglePost,
  hideComment,
  hidePost,
  likeActionComment,
  likeActionPoint, qrCode,
  removeAdoptActionPoint,
  reportComment,
  reportPost,
  searchTag,
  setMood,
  storePostMeta,
  translateComment,
  translatePost,
  updateActionPoint,
  updatePost,
  viewAPCollection,
  viewLikedBy,
  viewPostLink,
  viewRatingStatistics
} from '../variables';

import {GlobalService} from '../global/global.service';
import {ApiResponse, handleError} from '../utils';
import {PostModel} from 'src/app/models/post.model';
import {AuthService} from '../auth/auth.service';
import {NavigationEnd, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class PostService {
  public metaInfoEvent = new BehaviorSubject<any>('0');
  public metaInfoSubject: Observable<any> = this.metaInfoEvent.asObservable();

  public postEvent = new BehaviorSubject<PostModel>(null);
  public postSubject: Observable<PostModel> = this.postEvent.asObservable();

  constructor(
    public globalService: GlobalService,
    public auth: AuthService,
    public http: HttpClient,
  ) {
  }

  apiCreatePost(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(addStorePost, data, {headers: this.auth.multiPartHeader}).pipe(
      catchError(handleError)
    );
  }

  apiUpdatePost(id, data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(updatePost + '/' + id, data, {headers: this.auth.multiPartHeader}).pipe(
      catchError(handleError)
    );
  }

  apiCreateActionPoint(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(addStoreActionPoint, data, {headers: this.auth.multiPartHeader}).pipe(
      catchError(handleError)
    );
  }

  apiUpdateActionPoint(id, data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(updateActionPoint + '/' + id, data, {headers: this.auth.multiPartHeader}).pipe(
      catchError(handleError)
    );
  }

  apiGetSinglePost(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getSinglePost + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetSingleActionPoint(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getSingleActionPoint + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiAddCommentPost(data): Observable<ApiResponse> {
    data.referrer = this.globalService.lastRoute;
    return this.http.post<ApiResponse>(addCommentPost, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiEditCommentPost(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(editCommentPost, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiStorePostMeta(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(storePostMeta, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetComments(id, pagination): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getCommentList + '/' + id + '?page=' + pagination, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError),
    );
  }

  apiViewLikedBy(id, type, pagination): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(viewLikedBy + '/' + type + '/' + id + '?page=' + pagination, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError),
    );
  }

  apiLikeActionPoint(data): Observable<ApiResponse> {
    data.referrer = this.globalService.lastRoute;
    return this.http.post<ApiResponse>(likeActionPoint, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiAddRating(data): Observable<ApiResponse> {
    data.referrer = this.globalService.lastRoute;
    return this.http.post<ApiResponse>(addRating, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiAddAdoptActionPoint(data): Observable<ApiResponse> {
    data.referrer = this.globalService.lastRoute;
    return this.http.post<ApiResponse>(addAdoptActionPoint, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiRemoveAdoptAP(data): Observable<ApiResponse> {
    data.referrer = this.globalService.lastRoute;
    return this.http.post<ApiResponse>(removeAdoptActionPoint, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiSearchTag(query): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(searchTag + '/' + query, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError),
    );
  }

  reportPost(id): Observable<ApiResponse> {
    return this.http
      .get<ApiResponse>(reportPost + '/' + id, {headers: this.auth.jsonheader})
      .pipe(
        catchError(handleError),
      );
  }

  hidePost(id): Observable<ApiResponse> {
    return this.http
      .get<ApiResponse>(hidePost + '/' + id, {headers: this.auth.jsonheader})
      .pipe(
        catchError(handleError),
      );
  }

  likeActionComment(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(likeActionComment + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError),
    );
  }

  viewRatingStatistics(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(viewRatingStatistics + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError),
    );
  }

  apiTranslatePost(id, lang?: string): Observable<ApiResponse> {
    if(lang && lang !== ''){
      return this.http.get<ApiResponse>(translatePost + '/' + id + '/' + lang, {headers: this.auth.jsonheader}).pipe(
        catchError(handleError),
      );
    }else{
      return this.http.get<ApiResponse>(translatePost + '/' + id, {headers: this.auth.jsonheader}).pipe(
        catchError(handleError),
      );
    }
  }


  apiTranslateComment(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(translateComment + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError),
    );
  }

  getAPCollectionList(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(apCollectionList, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError),
    );
  }

  viewAPCollection(id, pagination): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(viewAPCollection + '/' + id + '?page=' + pagination, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError),
    );
  }

  apiDeletePost(id): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(deletePost + '/' + id, id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiCreateMoment(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(createMoment, data, {headers: this.auth.multiPartHeader}).pipe(
      catchError(handleError)
    );
  }

  apiSetMood(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(setMood, data, {headers: this.auth.multiPartHeader}).pipe(
      catchError(handleError)
    );
  }

  apiGetPostMeta(url) {
    return this.http.get<ApiResponse>(getPostMeta, {params: url, headers: this.auth.jsonheader}).pipe(
      catchError(handleError),
    );
  }

  apiHideComment(id) {
    return this.http.post<ApiResponse>(hideComment + '/' + id, {}, {headers: this.auth.multiPartHeader}).pipe(
      catchError(handleError)
    );
  }

  apiReportComment(id) {
    return this.http.post<ApiResponse>(reportComment + '/' + id, {}, {headers: this.auth.multiPartHeader}).pipe(
      catchError(handleError)
    );
  }

  apiGetPostOrActionPointOfQRCode(id) {
    return this.http.get<ApiResponse>(qrCode + '/' + id, {headers: this.auth.simpleHeader}).pipe(
      catchError(handleError),
    );
  }

  getMetaData(id, myurl): Observable<any> {
    const data = {
      postId: id,
      title: '',
      url: myurl,
      image: ''
    };
    let imagecounter = 0;
    return this.http.post(viewPostLink, {url: myurl}, {headers: this.auth.jsonheader, responseType: 'text'}).pipe(
      catchError(handleError),
      map((response: any) => {
        if (response !== 'no') {
          const parser = new DOMParser();
          const doc = parser.parseFromString(response, 'text/html');
          const meta: any = doc.head.querySelectorAll('meta');

          for (const m of meta) {
            if (m.getAttribute('property') === 'og:title') {
              data.title = m.getAttribute('content');
            }
            if (m.getAttribute('property') === 'og:image' && imagecounter === 0) {
              data.image = m.getAttribute('content');
              imagecounter++;
            }
          }
          if (data.title === '') {
            const title: any = doc.head.querySelector('title');
            data.title = title.text;
          }
        }
        return data;
      })
    );
  }

  setRatingColor(starsAvg, totalUsersRatedCount): string  {
    let colorRange = [];
    let colorOfEarth = '';

    const gradient = [[0,
      [255, 142, 20]
    ], [50,
      [255, 227, 56]
    ], [100,
      [194, 214, 26]
    ]];

    if (starsAvg === 0 && totalUsersRatedCount === 0) {
      colorOfEarth = 'rgb(0, 55, 61)';
    } else if (starsAvg === undefined) {
      colorOfEarth = 'rgb(255, 142, 20)';
    } else if (starsAvg < 50) {
      colorRange = [0, 1];
    } else {
      colorRange = [1, 2];
    }


    if (starsAvg !== undefined && totalUsersRatedCount !== 0) {
      const colRangeFirst = colorRange[0];
      //Get the two closest colors
      const firstColor = gradient[colRangeFirst][1];
      const secondColor = gradient[colorRange[1]][1];
      //Calculate ratio between the two closest colors
      const gradOne = Number(gradient[colorRange[0]][0]);
      const gradTwo = Number(gradient[colorRange[1]][0]);
      const firstColor_x = 100 * (gradOne / 100);
      const secondColor_x = 100 * (gradTwo / 100) - firstColor_x;
      const slider_x = 100 * (starsAvg / 100) - firstColor_x;
      const ratio = slider_x / secondColor_x;

      const w = ratio * 2 - 1;
      const w1 = (w + 1) / 2;
      const w2 = 1 - w1;
      colorOfEarth = 'rgb(' + Math.round(secondColor[0] * w1 + firstColor[0] * w2) + ',' +
        Math.round(secondColor[1] * w1 + firstColor[1] * w2) + ',' +
        Math.round(secondColor[2] * w1 + firstColor[2] * w2) + ')';
    }
    return colorOfEarth;
  }
}
