import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {
  addCompanyAdmin,
  assignCompanyActionPoint,
  companiesList,
  companyActionPointsList,
  companyAssignedAPList,
  companyEmployeeList,
  companyFollowerList,
  companyPostsList,
  companyTeamsList,
  createCompany,
  followCompany,
  getAdminCompanies,
  getCompaniesList,
  removeCompanyActionPoint,
  removeCompanyAdmin,
  removeCompanyEmployee,
  removeCompanyMember,
  removeCompanyProfileImg,
  removeCompanyTitleImg,
  unfollowCompany,
  updateCompany,
  userCompaniesFollows,
  viewCompany
} from '../variables';

import {GlobalService} from '../global/global.service';
import {ApiResponse, handleError} from '../utils';
import {AuthService} from '../auth/auth.service';
import {CompanyModel} from 'src/app/models/company.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  public companyEvent = new BehaviorSubject<CompanyModel>(null);
  public companySubject: Observable<CompanyModel> = this.companyEvent.asObservable();
  public newCompanyEvent = new BehaviorSubject<any>('0');

  constructor(
    public globalService: GlobalService,
    public auth: AuthService,
    public http: HttpClient
  ) {
  }


  apiCreateCompany(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(createCompany, data, {headers: this.auth.multiPartHeader}).pipe(
      catchError(handleError)
    );
  }

  apiUpdateCompany(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(updateCompany, data, {headers: this.auth.multiPartHeader}).pipe(
      catchError(handleError)
    );
  }

  apiGetCompanies(page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getCompaniesList + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetAdminCompanies(page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getAdminCompanies + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiViewCompany(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(viewCompany + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiFollowCompany(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(followCompany + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiUnfollowCompany(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(unfollowCompany + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiCompanyActionPointsList(id, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(companyActionPointsList + '/' + id + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiCompanyPostsList(id, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(companyPostsList + '/' + id + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiCompanyFollowerList(id, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(companyFollowerList + '/' + id + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiCompanyTeamsList(id, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(companyTeamsList + '/' + id + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetAllCompanies(page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(companiesList + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetCompanyEmployees(id, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(companyEmployeeList + '/' + id + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiAssignCompanyActionPoint(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(assignCompanyActionPoint, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiRemoveCompanyActionPoint(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(removeCompanyActionPoint, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiRemoveCompanyEmployee(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(removeCompanyEmployee, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetUserCompaniesFollows(page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(userCompaniesFollows + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiRemoveTitlePic(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(removeCompanyTitleImg + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiRemoveProfilePic(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(removeCompanyProfileImg + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }


  apiAddCompanyAdmin(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(addCompanyAdmin, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiRemoveCompanyAdmin(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(removeCompanyAdmin, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiRemoveCompanyMember(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(removeCompanyMember, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetCompanyAssignedAPs(id, search, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(companyAssignedAPList + '/' + id + '/' + search + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }
}
