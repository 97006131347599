import {Injectable} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class SetMetaService {

  constructor(
    private meta: Meta,
    private title: Title,
  ) {
  }

  setMetaData(title: string, description: string, image: string) {
    if (title && title !== '' || description && description !== '' || image && image !== '') {
      if (!image || image === null || image === undefined || image === '') {
        image = 'https://planeed.app/wp-content/uploads/2022/02/cropped-social-media-fallback-4.png';
      }
      this.title.setTitle(title);
      this.meta.updateTag({property: 'description', name: 'description', content: description});
      this.meta.updateTag({property: 'image', name: 'image', content: image});
      this.meta.updateTag({property: 'og:description', name: 'og:description', content: description});
      this.meta.updateTag({property: 'og:image', name: 'og:image', content: image});
      this.meta.updateTag({property: 'og:title', content: title});
      //this.meta.updateTag({property: 'og:type', content: title});
      //this.meta.updateTag({name: 'keywords', content: description})
    } else {
      this.setMetaData('planeed', 'Enter planeed: the ultimate choice for companies determined to redefine CSR, leaving an indelible mark on the world.', 'https://planeed.app/wp-content/uploads/2022/02/cropped-social-media-fallback-4.png')
    }
  }
}
