import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {
  adminCompaniesNoPagination,
  apListForCompany,
  apListForTeam,
  blockUser,
  changePassword,
  companiesMemberTeams,
  getAllTutorials,
  getAllUsersList,
  getGeneralOptions,
  getOtherUserProfile,
  getTeamListAsAdmin,
  getUserActionPoints,
  getuserFollowerList,
  getuserFollowingList,
  getUserPosts,
  getUserProfile,
  getUserSuggestions,
  getUserTeamAP,
  removeProfilePic,
  removeTitlePic,
  removeUser,
  reportUser,
  updateUserProfile,
  userCompaniesList,
  userfollow,
  userTeamList,
  userUnfollow
} from '../variables';
import {GlobalService} from '../global/global.service';
import {ApiResponse, handleError} from '../utils';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    public globalService: GlobalService,
    public auth: AuthService,
    public http: HttpClient
  ) {
  }

  apiUpdateProfile(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(updateUserProfile, data, {headers: this.auth.multiPartHeader}).pipe(
      catchError(handleError)
    );
  }

  apiChangePassword(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(changePassword, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiUserProfile(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getUserProfile, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiOtherUserProfile(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getOtherUserProfile + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetUserActionPoints(page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getUserActionPoints + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetUserTeamAP(page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getUserTeamAP + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetUserPosts(page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getUserPosts + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetUserFollowers(page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getuserFollowerList + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetUserFollowing(page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getuserFollowingList + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetAllUsersList(page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getAllUsersList + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetOtherUserFollowers(userId, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getuserFollowerList + '/' + userId + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetUserTeamList(page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(userTeamList + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetOtherTeamList(userId, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(userTeamList + '/' + userId + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetOtherUserActionPoints(userId, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getUserActionPoints + '/' + userId + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetOtherUserActionPointsNew(userId, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getUserTeamAP + '/' + userId + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetOtherUserPosts(userId, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getUserPosts + '/' + userId + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiUserUnfollow(userId): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(userUnfollow + '/' + userId, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiUserFollow(userId): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(userfollow + '/' + userId, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiSuggestUserFollow(userId, flag): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(userfollow + '/' + userId + '/' + flag, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGeneralOptions(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(getGeneralOptions, data, {headers: this.auth.simpleHeader}).pipe(
      catchError(handleError)
    );
  }

  apiUserSuggestions(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getUserSuggestions, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiRemoveProfilePic(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(removeProfilePic, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiRemoveTitlePic(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(removeTitlePic, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiDeleteUser(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(removeUser, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetTeamListAsAdmin(page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getTeamListAsAdmin + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetAllTutorials(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getAllTutorials, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiBlockUser(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(blockUser + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiReportUser(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(reportUser + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiUserCompaniesList(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(userCompaniesList, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiCompaniesMemberTeams(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(companiesMemberTeams + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apListForCompany(id, search, pageMyAP): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(apListForCompany + '/' + id + '/' + search + '?page=' + pageMyAP, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apListForTeam(id, search, pageMyAP): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(apListForTeam + '/' + id + '/' + search + '?page=' + pageMyAP, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiAdminCompaniesNoPagination(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(adminCompaniesNoPagination, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }
}
