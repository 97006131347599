import {IonicModule} from '@ionic/angular';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ActionPointPage} from './action-point.page';
import {HeaderModule} from '../../components/header/header.module';
import {ActionPointPageRoutingModule} from './action-point-routing.module';
import {ActionPointListModule} from '../../components/action-point-list/action-point-list.module';
import {TranslateModule} from '@ngx-translate/core';
import {SwiperModule} from 'swiper/angular';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ActionPointPageRoutingModule,
        ActionPointListModule,
        HeaderModule,
        TranslateModule,
        SwiperModule
    ],
    exports: [
        ActionPointPage
    ],
    declarations: [ActionPointPage]
})
export class ActionPointPageModule {
}
