import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {
  acceptInvitation,
  acceptJoinRequest,
  addTeam,
  addTeamAdmin,
  assignActionPoint, declineJoinRequest,
  editTeam,
  getAllTeamsList,
  getTeamFeeds,
  getTeamMembers,
  inviteMember,
  joinTeam,
  leaveTeam,
  rejectInvitation,
  removeActionPoint,
  removeTeamAdmin,
  removeTeamMember,
  removeTeamProfilePic,
  removeTeamTitleImage,
  teamActionPoints,
  teamFollow,
  teamUnfollow,
  usersToInvite,
  viewAllJoinRequests,
  viewInvitationList,
  viewJoinRequestsList,
  viewTeam
} from '../variables';

import {GlobalService} from '../global/global.service';
import {ApiResponse, handleError} from '../utils';
import {AuthService} from '../auth/auth.service';
import {TeamModel} from 'src/app/models/team.model';

@Injectable({
  providedIn: 'root'
})
export class TeamService {


  public teamEvent = new BehaviorSubject<TeamModel>(null);
  public teamSubject: Observable<TeamModel> = this.teamEvent.asObservable();
  public teamDeleteEvent = new BehaviorSubject<any>('0');
  public teamDeleteSubject: Observable<any> = this.teamDeleteEvent.asObservable();

  constructor(public globalService: GlobalService,
              public auth: AuthService,
              public http: HttpClient
  ) {
  }

  apiCreateTeam(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(addTeam, data, {headers: this.auth.multiPartHeader}).pipe(
      catchError(handleError)
    );
  }

  apiViewTeam(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(viewTeam + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetTeamPost(id, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getTeamFeeds + '/' + id + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiEditTeam(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(editTeam, data, {headers: this.auth.multiPartHeader}).pipe(
      catchError(handleError)
    );
  }

  apiJoinTeam(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(joinTeam + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiJoinTeamByCompany(id, companyId): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(joinTeam + '/' + id + '/' + companyId, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiAllTeamList(page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getAllTeamsList + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiTeamMembers(id, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getTeamMembers + '/' + id + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiLeaveTeam(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(leaveTeam + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiLeaveTeamByCompany(id, companyId): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(leaveTeam + '/' + id + '/' + companyId, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiAddTeamAdmin(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(addTeamAdmin, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiRemoveTeamAdmin(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(removeTeamAdmin, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }


  apiAssignActionPoint(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(assignActionPoint, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiRemoveActionPoint(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(removeActionPoint, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiRemoveTitlePic(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(removeTeamTitleImage + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiRemoveProfilePic(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(removeTeamProfilePic + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiAllViewJoinRequests(page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(viewAllJoinRequests + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiViewInvitationList(page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(viewInvitationList + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiAcceptJoinRequest(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(acceptJoinRequest, data, {headers: this.auth.multiPartHeader}).pipe(
      catchError(handleError)
    );
  }

  apiDeclineJoinRequest(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(declineJoinRequest, data, {headers: this.auth.multiPartHeader}).pipe(
      catchError(handleError)
    );
  }

  apiRemoveTeamMember(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(removeTeamMember, data, {headers: this.auth.multiPartHeader}).pipe(
      catchError(handleError)
    );
  }

  apiUsersToInvite(id, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(usersToInvite + '/' + id + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiUsersToInviteTeamSearch(id, searchPhrase, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(usersToInvite + '/' + id + '/' + searchPhrase + '?search=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiInviteMember(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(inviteMember, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  viewJoinRequestsList(id, page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(viewJoinRequestsList + '/' + id + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiAcceptInvitation(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(acceptInvitation, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiRejectInvitation(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(rejectInvitation, data, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  teamFollow(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(teamFollow + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  teamUnfollow(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(teamUnfollow + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiGetTeamAPs(id, pageMyAP): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(teamActionPoints + '/' + id + '?page=' + pageMyAP, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }
}
