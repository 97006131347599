// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  margin: 0;
  margin-top: 8px;
  padding: 0;
  border-radius: 0;
  --ion-background-color: var(--planeed-color-white);
  background-color: white;
}

ion-list {
  padding: 0;
  margin: 0;
}

ion-row {
  margin: 0;
}
ion-row ion-icon {
  padding: 2px 0 0 0;
}

p {
  display: inline;
}

.avatar-box {
  height: 100%;
  padding-top: 16px;
}

.social-counter {
  margin-bottom: 10px;
  color: var(--ion-color-medium);
  padding: 2px 10px 0 4px;
}
.social-counter ion-icon {
  width: 13px;
  height: 13px;
}
.social-counter span {
  margin: 0 10px 0 5px;
  font-size: 13px;
}
.social-counter .leaf {
  color: var(--ion-color-warning);
}

.social-counter-web {
  margin-bottom: 10px;
  color: var(--ion-color-medium);
  padding: 0 10px 0 4px;
}
.social-counter-web ion-icon {
  padding: 3px 0 0 0;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .social-counter-web ion-icon {
    height: 10px;
    width: 10px;
  }
}
@media only screen and (min-width: 1920px) {
  .social-counter-web ion-icon {
    height: 13px;
    width: 13px;
  }
}
.social-counter-web span {
  margin: 0 10px 0 5px;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .social-counter-web span {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1920px) {
  .social-counter-web span {
    font-size: 14px;
  }
}
.social-counter-web .leaf {
  color: var(--ion-color-warning);
}

ion-item img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin: 0 10px 0 0;
  border-radius: 50%;
}

@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .web-header {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1920px) {
  .web-header {
    font-size: 14px;
  }
}

.header {
  font-size: 14px;
}

.impact-score-box ion-icon {
  background: var(--planeed-color-white);
  margin-top: -12px;
  position: absolute;
  margin-left: -12px;
  width: 26px;
  font-size: 15px;
}

.score-heading {
  text-align: center;
  color: var(--ion-color-medium);
}

.score-content-center2 {
  font-size: 16px;
  color: var(--ion-color-warning);
  text-align: center;
  padding-top: 32px;
}

.impact-score-box {
  border: 2px solid var(--ion-color-warning);
  width: 64px;
  height: 64px;
  text-align: center;
  font-size: 16px;
  position: relative;
  border-radius: 50%;
  color: var(--ion-color-warning);
  margin: 0 auto;
}

.score-content {
  position: relative;
  margin-top: -80px;
  padding-bottom: 16px;
}

.content {
  width: 70px;
  text-align: right;
}

.content-web {
  text-align: right;
}
.content-web .score-content {
  position: relative;
  padding-bottom: 12px;
}
.content-web ion-icon {
  background: var(--planeed-color-white);
  margin-top: -12px;
  position: absolute;
  margin-left: -12px;
  width: 26px;
  font-size: 15px;
}
.content-web .impact-score-box-web {
  border: 2px solid var(--ion-color-warning);
  width: 44px;
  height: 44px;
  text-align: center;
  font-size: 13px;
  position: relative;
  border-radius: 50%;
  color: var(--ion-color-warning);
  margin: 0 auto;
}
.content-web .score-content-center2 {
  font-size: 16px;
  color: var(--ion-color-warning);
  text-align: center;
  padding-top: 40px;
}

@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .web-profile-image {
    width: 50px;
    height: 40px;
    padding: 0 10px 0 0;
  }
  .web-profile-image img {
    width: 40px;
    height: 40px;
  }
}
@media only screen and (min-width: 1920px) {
  .web-profile-image {
    width: 60px;
    height: 50px;
    padding: 0 10px 0 0;
  }
  .web-profile-image img {
    width: 50px;
    height: 50px;
  }
}

.isPaddingLeft {
  --padding-start: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
