import {Component, OnInit} from '@angular/core';
import {TeamModel} from "../../models/team.model";
import {CompanyModel} from "../../models/company.model";
import {ApiResponse} from "../../services/utils";
import {UserService} from "../../services/user/user.service";
import {CompanyService} from "../../services/company/company.service";

@Component({
  selector: 'app-my-teams-organisations',
  templateUrl: './my-teams-organisations.component.html',
  styleUrls: ['./my-teams-organisations.component.scss'],
})
export class MyTeamsOrganisationsComponent implements OnInit {

  isTeamsLoading: boolean = false;
  isOrganLoading: boolean = false;
  teamsPage: number = 1;
  organPage: number = 1;

  myTeams: TeamModel[] = [];
  allMyTeams: TeamModel[] = [];
  myOrganisations: CompanyModel[] = [];
  allMyOrganisations: CompanyModel[] = [];
  showMoreTeamsButton: boolean = false;
  moreTeamsShown: boolean = false;
  showMoreOrganButtons: boolean = false;
  moreOrganShown: boolean = false;

  constructor(
    private userService: UserService,
    private companyService: CompanyService,
  ) {
  }

  ngOnInit() {
    this.teamsPage = 1;
    this.isTeamsLoading = true;
    this.isOrganLoading = true;
    this.loadMyOrganisations();
    this.loadMyTeams();
  }


  loadMyTeams() {
    this.userService.apiGetTeamListAsAdmin(this.teamsPage).subscribe({
      next: (result: ApiResponse) => {
        if (result.status) {
          for (const data of result.data.data) {
            if (data.isAdmin) {
              if (!this.allMyTeams.some(elem => elem.id === data.id)) {
                this.allMyTeams.push(data);
              }
            }
          }
          this.teamsPage += 1;

          if (result.data.pagination.currentPage < result.data.pagination.totalPages) {
            this.isTeamsLoading = true;
            this.loadMyTeams();
            return;
          } else {
            this.isTeamsLoading = false;
          }
        } else {
          this.isTeamsLoading = false;
        }

      },
      complete: () => {
        if (!this.isTeamsLoading) {
          if (this.allMyTeams.length > 2) {
            this.myTeams = this.allMyTeams.slice(0, 2);
            this.showMoreTeamsButton = true;
          } else {
            this.myTeams = this.allMyTeams;
            this.showMoreTeamsButton = false;
          }
        }
      }
    });
  }

  loadMyOrganisations() {
    this.companyService.apiGetAdminCompanies(this.organPage).subscribe({
      next: (result: ApiResponse) => {
        if (result.status) {
          for (const data of result.data.data) {
            if (!this.allMyOrganisations.some(elem => elem.id === data.id)) {
              this.allMyOrganisations.push(data);
            }
          }
          this.organPage += 1;
          if (result.data.pagination.currentPage < result.data.pagination.totalPages) {
            this.isOrganLoading = true;
            this.loadMyOrganisations();
            return;
          } else {
            this.isOrganLoading = false;
          }
        } else {
          this.isOrganLoading = false;
        }

      },
      complete: () => {
        if (!this.isOrganLoading) {
          if (this.allMyOrganisations.length > 2) {
            this.myOrganisations = this.allMyOrganisations.slice(0, 2);
            this.showMoreOrganButtons = true;
          } else {
            this.myOrganisations = this.allMyOrganisations;
            this.showMoreOrganButtons = false;
          }
        }
      }
    });
  }

  loadMoreMyTeams() {

    if (this.moreTeamsShown) {
      this.myTeams = this.allMyTeams.slice(0, 2);
    } else {
      this.myTeams = this.allMyTeams;
    }
    this.moreTeamsShown = !this.moreTeamsShown;
  }

  loadMoreMyCompanies() {
    if (this.moreOrganShown) {
      this.myOrganisations = this.allMyOrganisations.slice(0, 2);
    } else {
      this.myOrganisations = this.allMyOrganisations;
    }
    this.moreOrganShown = !this.moreOrganShown;
  }
}
