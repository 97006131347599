import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonContent, IonSegment, Platform} from '@ionic/angular';
import {FeedService} from 'src/app/services/feed/feed.service';
import {GlobalService} from 'src/app/services/global/global.service';
import {ApiResponse} from 'src/app/services/utils';
import {SwiperOptions} from 'swiper';
import {SwiperComponent} from 'swiper/angular';
import {SetMetaService} from "../../services/set-meta/set-meta.service";

@Component({
  selector: 'app-action-point',
  templateUrl: 'action-point.page.html',
  styleUrls: ['action-point.page.scss']
})
export class ActionPointPage implements OnInit {
  @ViewChild(IonContent, {static: false}) apcontent: IonContent;
  @ViewChild('swiper') swiper: SwiperComponent;
  @ViewChild('segment') segment: IonSegment;
  @Input('component') component: boolean = false;

  actionPoints = [];
  totalActionPoints = 0;
  page = 1;
  impactResponseFlag = false;
  type = 'newest';
  config: SwiperOptions = {
    pagination: true,
    height: 400
  };
  loading = false;
  isWeb: boolean = false;

  constructor(
    private feedService: FeedService,
    private globalService: GlobalService,
    private platform: Platform,
    private setMeta: SetMetaService,
  ) {
  }

  ngOnInit(): void {
    this.isWeb = !this.platform.is('capacitor');
    this.loadActionPoints();

    this.globalService.homeScrollTop.subscribe(val => {
      if (val !== null && val === 'action-point') {
        this.apcontent.scrollToTop();
        this.loadActionPoints();
      }
    });
  }

  doRefresh(event) {
    setTimeout(() => {
      this.loadActionPoints();
      event.target.complete();
    }, 1000);
  }

  loadActionPoints() {
    this.loading = true;
    this.page = 1;
    this.actionPoints = [];
    if (this.impactResponseFlag === false) {
      this.impactResponseFlag = true;
      this.feedService.getActionPointList(this.page, this.type).subscribe((result: ApiResponse) => {
        if (result.status) {
          this.actionPoints = result.data.data;
          this.totalActionPoints = result.data.pagination?.total;
          this.page++;
        }
        this.impactResponseFlag = false;
        this.loading = false;
      });
    }
  }

  loadMoreActionPoint(event) {
    this.loading = true;
    if (this.impactResponseFlag === false) {
      this.impactResponseFlag = true;

      this.feedService.getActionPointList(this.page, this.type).subscribe((result: ApiResponse) => {
        if (result.status) {
          result.data.data.forEach(element => {
            this.actionPoints.push(element);
          });
          this.page++;
        }
        event.target.complete();
        this.impactResponseFlag = false;
        this.loading = false;
      });
    }
  }

  onSlideChange(event) {
    if (event[0].activeIndex === 0) {
      this.type = 'newest';
      this.segment.value = 'newest';
    }
    if (event[0].activeIndex === 1) {
      this.type = 'top';
      this.segment.value = 'top';
    }
    if (event[0].activeIndex === 2) {
      this.type = 'discover';
      this.segment.value = 'discover';
    }
  }

  segmentChanged(event) {
    this.type = event.target.value;
    this.loadActionPoints();
  }
}

