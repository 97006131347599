import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {UserService} from 'src/app/services/user/user.service';
import {ApiResponse} from 'src/app/services/utils';

@Component({
  selector: 'app-post-modal',
  templateUrl: './post-modal.component.html',
  styleUrls: ['./post-modal.component.scss'],
})
export class PostModalComponent implements OnInit {

  descPost = 'Loading...';

  constructor(
    private modalController: ModalController,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.loadGeneralOptions();
  }

  public async closeModal(): Promise<void> {
    await this.modalController.dismiss(null, 'cancel');
  }


  loadGeneralOptions() {
    const fieldsen = {
      options: ['descr_post_EN']
    };

    const fieldsde = {
      options: ['descr_post_DE']
    };
    const lang = localStorage.getItem('selectLanguage') || 'de';
    if (lang === 'en') {
      this.userService.apiGeneralOptions(fieldsen).subscribe((result: ApiResponse) => {
        if (result.status) {
          result.data.forEach(d => {
            if (d.slug === 'descr_post_EN') {
              this.descPost = d.value;
            }
          });
        }
      });
    } else {
      this.userService.apiGeneralOptions(fieldsde).subscribe((result: ApiResponse) => {
        if (result.status) {
          result.data.forEach(d => {
            if (d.slug === 'descr_post_DE') {
              this.descPost = d.value;
            }
          });
        }
      });
    }
  }
}
