import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavController} from '@ionic/angular';
import {PostModel} from 'src/app/models/post.model';
import {PostService} from '../../services/post/post.service';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
})
export class ReadMoreComponent implements OnInit {

  @Input() item: PostModel;
  @Output() itemChange = new EventEmitter<PostModel>;
  @Input() len: number;
  @Input() translated: boolean;
  @Output() readMoreEvent = new EventEmitter<any>();
  @Output() translateEvent = new EventEmitter<boolean>();
  //@Output() translateEvent = new EventEmitter<any>();

  showMore = false;
  translateFlag = false;
  originalDescription = '';
  description = '';

  constructor(public navController: NavController,
              private postService: PostService) {
  }


  ngOnInit() {
    this.description = this.item.description;
    this.originalDescription = this.item.description;
  }

  translatePost(item) {
    if (!this.translateFlag) {
      this.translateFlag = !this.translateFlag;
      if(!this.item.postTranslations || !this.item.postTranslations.description){
        this.postService.apiTranslatePost(item?.id).subscribe((result: any) => {
          if (result.status) {
            this.translateEvent.emit(this.translateFlag);
            this.itemChange.emit(result.data);
            this.item.description = result.data.postTranslations.description;
          }
        });
      }else {
        this.description = this.item.postTranslations.description;
        this.translateEvent.emit(this.translateFlag);
      }
    } else {
      this.description = this.originalDescription;
      this.item.description = this.originalDescription;
      this.translateFlag = !this.translateFlag;
      this.translateEvent.emit(this.translateFlag);
    }


  }

  trimString(_content, length) {
    if (_content?.length > length) {
      this.readMoreEvent.emit({val: 'yes'});
      return _content.substring(0, length) + '...';
    } else {
      this.readMoreEvent.emit({val: 'no'});
      return _content;
    }
  }

  checkLength(_content, _length) {
    return _content?.length < _length;
  }

  openOtherProfile(id) {
    this.navController.navigateForward(['/app/home/other-profile/' + id]);
  }

  getContent(item: any, description: any) {
    let str = description;
    const arrToReturn = [];
    let counter = 0;

    item.userTag.forEach((element, index) => {
      const q = '@' + element.username;
      if (str.indexOf(q) !== -1) {
        const startIndex = str.indexOf(q);
        const endIndex = startIndex + q.length;
        if (str.indexOf(q) === 0) {
          arrToReturn.push(counter);
          str = str.substring(endIndex, str.length);
        } else {
          arrToReturn.push(str.substring(0, startIndex));
          arrToReturn.push(counter);
          str = str.substring(endIndex, str.length);
        }
      }
      if (str.length > 0 && index + 1 === item.userTag.length) {
        arrToReturn.push(str);
      }
      counter++;
    });

    if (counter !== 0 && arrToReturn.length !== 0) {
      return arrToReturn;
    } else {
      arrToReturn.push(str);
      return arrToReturn;
    }
  }

  checkIsString(txt) {
    return typeof txt === 'string';
  }
}
