import {Location} from '@angular/common';
import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {AlertController, IonRouterOutlet, NavController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token,} from '@capacitor/push-notifications';
import {NativeAudio} from "@capgo/native-audio";
import {GlobalService} from './services/global/global.service';
import {NotificationsService} from './services/notifications/notifications.service';
import {StorageService} from './services/storage/storage.service';
import {AuthService} from "./services/auth/auth.service";
import {SetMetaService} from "./services/set-meta/set-meta.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(IonRouterOutlet) routerOutlet: IonRouterOutlet;
  language: string = '';
  showComponents: boolean = false;
  showTopActionPoints: boolean = false;
  showMyProfile: boolean = false;
  showMyOrganisations: boolean = false;
  isAuthenticated: boolean = false;
  showExploreMoreButton: boolean = false;
  showLoginSide: boolean = false;
  headerType: string = 'global';

  constructor(
    private translate: TranslateService,
    private router: Router,
    private location: Location,
    private navController: NavController,
    private alertController: AlertController,
    public globalService: GlobalService,
    private notificationService: NotificationsService,
    public platform: Platform,
    private storage: StorageService,
    private ngZone: NgZone,
    private auth: AuthService,
    private setMeta: SetMetaService
  ) {
    if (this.globalService.isBrowser) {
      if (navigator.language.toLocaleLowerCase().includes('de') === true) {
        this.language = localStorage.getItem('selectLanguage') || '';
        if (this.language === '') {
          localStorage.setItem('selectLanguage', 'de');
          this.language = 'de';
        }
      } else {
        this.language = localStorage.getItem('selectLanguage') || '';
        if (this.language === '') {
          localStorage.setItem('selectLanguage', 'en');
          this.language = 'en';
        }
      }
    } else {
      this.language = 'en';
    }

    this.translate.use(this.language);
    this.globalService.languageEvent.next(this.language);
    this.initializeApp();
  }

  async ngOnInit() {
    this.setMeta.setMetaData('', '', '');
    if (this.globalService.isBrowser) {
      this.showComponents = window.innerWidth > this.globalService.breakPointShowComponents;
      window.addEventListener('resize', () => {
        this.showComponents = window.innerWidth > this.globalService.breakPointShowComponents;
      });
    } else {
      this.showComponents = false;
    }


    this.router.events.subscribe(async (e) => {
      if (e instanceof NavigationStart) {
        this.globalService.lastRoute = this.router.url;
      }
      if (e instanceof NavigationEnd) {
        this.isAuthenticated = this.auth.isAuthenticated;
        this.showExploreMoreButton = this.router.url.startsWith('/anonymous/')
        this.showTopActionPoints = this.router.url !== '/app/action-point' && this.router.url !== '/login' && this.router.url !== '/register' && !this.showExploreMoreButton;
        this.showMyProfile = this.router.url !== '/app/profile';
        this.showMyOrganisations = this.router.url !== '/app/team';
        this.showLoginSide = !this.auth.isAuthenticated && this.router.url !== '/login' && this.router.url !== '/register' && !this.showExploreMoreButton;
        this.headerType = this.router.url.startsWith('/app/team/view-team') ?
          'team' : this.router.url.startsWith('/app/team/company/view-company') ? 'company' : 'global';
      }
    });

    await this.platform.ready();

    if (this.platform.is('capacitor') && this.globalService.isBrowser) {

      // App opened by URL
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.ngZone.run(async () => {
          const slug = event.url.split('://')
          if (slug) {
            if (slug.length > 1 && slug[1].includes('qr')) {
              const url = slug[1].split('/');
              const codeId = url[url.length - 1];
              await this.router.navigateByUrl(`/qr/${codeId}`);
            } else if (slug.length > 1 && slug[1].includes('ap-img')) {
              const url = slug[1].split('/');
              const codeId = url[url.length - 1];
              await this.router.navigateByUrl(`/ap-img/${codeId}`);
            } else if (slug.length > 1 && slug[1].includes('share')) {
              const url = slug[1].split('/');
              const postId = url[url.length - 1];
              await this.router.navigateByUrl(`/share/${postId}`);
            } else {
              await this.router.navigate([''])
            }
          }
        });
      });
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      const permStatus = await PushNotifications.checkPermissions();
      //console.log('Permission Status', permStatus);
      if (permStatus.receive === 'granted') {
        await PushNotifications.register();
      } else {
        const ding = await PushNotifications.requestPermissions();
        console.log('Ding', ding);
      }
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
          console.log('ERROR PushNotification');
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token: Token) => {
          localStorage.setItem('deviceToken', token.value);
          //   alert('Push registration success, token: ' + token.value);
        }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          console.error('Error on registration: ' + JSON.stringify(error));
        }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          console.log(notification);
          console.error('Push received: ' + JSON.stringify(notification));
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          this.openNotification(notification);
        }
      );


      try {
        await NativeAudio.preload({
          assetId: 'like',
          assetPath: 'assets/sounds/like.wav',
          volume: 1.0,
        });
      } catch (e) {
        console.log('Error preload Audio Like', e);
      }

      try {
        await NativeAudio.preload({
          assetId: 'adopt',
          assetPath: 'assets/sounds/adopt.wav',
          volume: 1.0,
        });
      } catch (e) {
        console.log('Error preload Audio Adopt', e);
      }

      try {
        await NativeAudio.preload({
          assetId: 'impact',
          assetPath: 'assets/sounds/impact.wav',
          volume: 1.0,
        });
      } catch (e) {
        console.log('Error preload Audio impact', e);
      }

      try {
        await NativeAudio.preload({
          assetId: 'impact2',
          assetPath: 'assets/sounds/impact2.wav',
          volume: 1.0,
        });
      } catch (e) {
        console.log('Error preload Audio Impact2', e);
      }

      try {
        await NativeAudio.preload({
          assetId: 'moment',
          assetPath: 'assets/sounds/moment.wav',
          volume: 1.0,
        });
      } catch (e) {
        console.log('Error preload Audio Moment', e);
      }
    }
    await this.storage.checkForCreatedStorage();
  }

  openNotification(res) {
    // whenever user click on notification app jump to corresponding page
    if (res.notification) {
      if (res.notification.data.target === 'Action Point' || res.notification.data.target === 'Adopted Action Point') {
        this.navController.navigateForward(['/app/home/view-post/action_point/' + res.notification.data.targetId + '/detail']);
      }
      if (res.notification.data.target === 'Post') {
        this.navController.navigateForward(['/app/home/view-post/post/' + res.notification.data.targetId + '/detail']);
      }
      if (res.notification.data.target === 'User') {
        this.navController.navigateForward(['/app/home/other-profile/' + res.notification.data.targetId]);
      }
      if (res.notification.data.target === 'Company') {
        this.navController.navigateForward(['/app/team/company/other-company/' + res.notification.data.targetId]);
      }
      if (res.notification.data.target === 'Team') {
        this.navController.navigateForward(['/app/team/other-team/' + res.notification.data.targetId]);
      }
      if (res.notification.data.target === 'Story') {
        this.notificationService.scrollTopWhenStoryEvent.next('Scroll');
      }
    }
  }

  initializeApp() {
    // handling hardware back button
    this.platform.backButton.subscribeWithPriority(0, () => {
      if (this.routerOutlet && this.routerOutlet.canGoBack()) {
        this.routerOutlet.pop();
      } else if (this.router.url === '/app/home') {
        this.confirmExitApp();
      } else if (this.router.url === '/app/profile/edit-profile') {
        // this case handling in edit profile page
      } else {
        this.location.back();
      }
    });

  }

  async confirmExitApp() {
    // alert when there is no back step close app alert will show
    const alert = await this.alertController.create({
      header: this.translate.instant('hdl_exit_app'),
      message: this.translate.instant('txt_exit_app'),
      buttons: [
        {
          text: this.translate.instant('lbl_yes'),
          id: 'confirm-button',
          handler: () => {
            App.exitApp();
          }
        },
        {
          text: this.translate.instant('lbl_no'),
          role: 'cancel',
          cssClass: 'cancel-button',
          id: 'cancel-button',
        }
      ]
    });
    await alert.present();
  }


  async discoverMore() {
    if (this.platform.is('capacitor')) {
      await this.navController.navigateForward(['/login'])
    } else {
      if (this.globalService.isBrowser) {
        const companyId = localStorage.getItem('anonymousCompany');
        await this.navController.navigateForward([`/app/team/company/other-company/${companyId}`])
      }
    }
  }
}
