import {of} from 'rxjs';

export class ApiResponse {
  public status: boolean;
  public error: any;
  public data: any;
  public message: any;
}

export const handleResponse = (response) => {
  if (!response.hasOwnProperty('data') && !response.data) {
    throw new Error('Value expected!');
  }
  return response.data;
};

export const handleError = (error: any) => {
  const errorMessage = [];
  const errors: any = Object.keys(error.error.error);
  const checkVar = error.error.error;
  if (typeof checkVar === 'object') {
    errors.forEach(element => {
      errorMessage.push(error.error.error[element][0]);
    });
  } else {
    errorMessage.push(error.error.error);
  }
  const res = {} as ApiResponse;
  res.error = errorMessage;
  res.status = false;
  return of(res);
};

export const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli',
  'August', 'September', 'Oktober', 'November', 'Dezember'];

export const monthsNumbers = ['01', '02', '03', '04', '05', '06', '07',
  '08', '09', '10', '11', '12'];

export const timestampInMin = () => Math.floor(Date.now() / 1000 / 60);

export const fileUploadSize = 6291456;

export const sdgs = [
  {id: 1, name: 'No Poverty', checked: false},
  {id: 2, name: 'Zero Hunger', checked: false},
  {id: 3, name: 'Good Health and Well-Being', checked: false},
  {id: 4, name: 'Quality Education', checked: false},
  {id: 5, name: 'Gender Quality', checked: false},
  {id: 6, name: 'Clean Water and Sanitation', checked: false},
  {id: 7, name: 'Affordable and Clean Energy', checked: false},
  {id: 8, name: 'Decent Work and Economic Growth', checked: false},
  {id: 9, name: 'Industry, Innovation and Infrastructure', checked: false},
  {id: 10, name: 'Reduced Inequalities', checked: false},
  {id: 11, name: 'Sustainable Cities and Communities', checked: false},
  {id: 12, name: 'Responsible Consumption and Production', checked: false},
  {id: 13, name: 'Climate Action', checked: false},
  {id: 14, name: 'Life below Water', checked: false},
  {id: 15, name: 'Life on Land', checked: false},
  {id: 16, name: 'Peace, Justice and Strong Institutions', checked: false},
  {id: 17, name: 'Partnership for the Goals', checked: false},
];

export const sdgActive = [
  {id: 1, path: 'assets/icon/ap_sdg/active/icon-nopoverty.svg'},
  {id: 2, path: 'assets/icon/ap_sdg/active/icon-zerohunger.svg'},
  {id: 3, path: 'assets/icon/ap_sdg/active/icon-goodhealthandwellbeing.svg'},
  {id: 4, path: 'assets/icon/ap_sdg/active/icon-qualityeducation.svg'},
  {id: 5, path: 'assets/icon/ap_sdg/active/icon-genderequality.svg'},
  {id: 6, path: 'assets/icon/ap_sdg/active/icon-cleanwaterandsanitation.svg'},
  {id: 7, path: 'assets/icon/ap_sdg/active/icon-affordableandcleanenergy.svg'},
  {id: 8, path: 'assets/icon/ap_sdg/active/icon-decentworkandeconomicgrowth.svg'},
  {id: 9, path: 'assets/icon/ap_sdg/active/icon-industryinnovationandinfrastructure.svg'},
  {id: 10, path: 'assets/icon/ap_sdg/active/icon-requiredinequalities.svg'},
  {id: 11, path: 'assets/icon/ap_sdg/active/icon-sustainablecitiesandcommunities.svg'},
  {id: 12, path: 'assets/icon/ap_sdg/active/icon-responsiblesonsumptionandproduction.svg'},
  {id: 13, path: 'assets/icon/ap_sdg/active/icon-climateaction.svg'},
  {id: 14, path: 'assets/icon/ap_sdg/active/icon-lifebelowater.svg'},
  {id: 15, path: 'assets/icon/ap_sdg/active/icon-lifeonland.svg'},
  {id: 16, path: 'assets/icon/ap_sdg/active/icon-peacejusticeandstronginstitutions.svg'},
  {id: 17, path: 'assets/icon/ap_sdg/active/icon-partnershipsforthegoals.svg'},
];

export const sdgInactive = [
  {id: 1, path: 'assets/icon/ap_sdg/inactive/icon-nopoverty.svg'},
  {id: 2, path: 'assets/icon/ap_sdg/inactive/icon-zerohunger.svg'},
  {id: 3, path: 'assets/icon/ap_sdg/inactive/icon-goodhealthandwellbeing.svg'},
  {id: 4, path: 'assets/icon/ap_sdg/inactive/icon-qualityeducation.svg'},
  {id: 5, path: 'assets/icon/ap_sdg/inactive/icon-genderequality.svg'},
  {id: 6, path: 'assets/icon/ap_sdg/inactive/icon-cleanwaterandsanitation.svg'},
  {id: 7, path: 'assets/icon/ap_sdg/inactive/icon-affordableandcleanenergy.svg'},
  {id: 8, path: 'assets/icon/ap_sdg/inactive/icon-decentworkandeconomicgrowth.svg'},
  {id: 9, path: 'assets/icon/ap_sdg/inactive/icon-industryinnovationandinfrastructure.svg'},
  {id: 10, path: 'assets/icon/ap_sdg/inactive/icon-requiredinequalities.svg'},
  {id: 11, path: 'assets/icon/ap_sdg/inactive/icon-sustainablecitiesandcommunities.svg'},
  {id: 12, path: 'assets/icon/ap_sdg/inactive/icon-responsiblesonsumptionandproduction.svg'},
  {id: 13, path: 'assets/icon/ap_sdg/inactive/icon-climateaction.svg'},
  {id: 14, path: 'assets/icon/ap_sdg/inactive/icon-lifebelowater.svg'},
  {id: 15, path: 'assets/icon/ap_sdg/inactive/icon-lifeonland.svg'},
  {id: 16, path: 'assets/icon/ap_sdg/inactive/icon-peacejusticeandstronginstitutions.svg'},
  {id: 17, path: 'assets/icon/ap_sdg/inactive/icon-partnershipsforthegoals.svg'},
];
export const sdgGray = [
  {id: 1, path: 'assets/icon/ap_sdg/gray/icon-nopoverty.svg'},
  {id: 2, path: 'assets/icon/ap_sdg/gray/icon-zerohunger.svg'},
  {id: 3, path: 'assets/icon/ap_sdg/gray/icon-goodhealthandwellbeing.svg'},
  {id: 4, path: 'assets/icon/ap_sdg/gray/icon-qualityeducation.svg'},
  {id: 5, path: 'assets/icon/ap_sdg/gray/icon-genderequality.svg'},
  {id: 6, path: 'assets/icon/ap_sdg/gray/icon-cleanwaterandsanitation.svg'},
  {id: 7, path: 'assets/icon/ap_sdg/gray/icon-affordableandcleanenergy.svg'},
  {id: 8, path: 'assets/icon/ap_sdg/gray/icon-decentworkandeconomicgrowth.svg'},
  {id: 9, path: 'assets/icon/ap_sdg/gray/icon-industryinnovationandinfrastructure.svg'},
  {id: 10, path: 'assets/icon/ap_sdg/gray/icon-requiredinequalities.svg'},
  {id: 11, path: 'assets/icon/ap_sdg/gray/icon-sustainablecitiesandcommunities.svg'},
  {id: 12, path: 'assets/icon/ap_sdg/gray/icon-responsiblesonsumptionandproduction.svg'},
  {id: 13, path: 'assets/icon/ap_sdg/gray/icon-climateaction.svg'},
  {id: 14, path: 'assets/icon/ap_sdg/gray/icon-lifebelowater.svg'},
  {id: 15, path: 'assets/icon/ap_sdg/gray/icon-lifeonland.svg'},
  {id: 16, path: 'assets/icon/ap_sdg/gray/icon-peacejusticeandstronginstitutions.svg'},
  {id: 17, path: 'assets/icon/ap_sdg/gray/icon-partnershipsforthegoals.svg'},
];
