import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {getAllNotifications, markAllSeenNotification, markSeenNotification, unreadNotification} from '../variables';

import {GlobalService} from '../global/global.service';
import {ApiResponse, handleError} from '../utils';
import {AuthService} from '../auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  notificationEvent = new BehaviorSubject(0);
  scrollTopWhenStoryEvent = new BehaviorSubject('');

  constructor(public globalService: GlobalService,
              public auth: AuthService,
              public http: HttpClient
  ) {
  }

  apiGetAllNotifications(page): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getAllNotifications + '?page=' + page, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiMarkASeenNotification(id): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(markSeenNotification + '/' + id, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiMarkAllSeenNotifications(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(markAllSeenNotification, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

  apiUnreadNotification(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(unreadNotification, {headers: this.auth.jsonheader}).pipe(
      catchError(handleError)
    );
  }

}
