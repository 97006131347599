import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import {NavController, Platform} from '@ionic/angular';
import {GlobalService} from 'src/app/services/global/global.service';
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {Router} from "@angular/router";
import {isPlatformBrowser, Location} from '@angular/common';
import {AuthService} from "../../services/auth/auth.service";
import {environment} from "../../../environments/environment";
import {getPlatformId} from "@capacitor/core/types/util";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() type;
  @Input('side') side: string = '';
  @Input('onlyArrow') onlyArrow: boolean = false;
  @Input('name') name: string = '';
  @Input('arrowAllowed') arrowAllowed: boolean = false;
  @Output('backDetect') backDetect: EventEmitter<any> = new EventEmitter<any>();
  @Input('hide') hide: boolean = false;

  standardHeaderRoutes: string[] = [
    '/app/leaf',
    '/app/action-point',
    '/app/team',
    '/app/profile'
  ]

  registrationRoutes: string[] = [
    '/login',
    '/register'
  ]

  id;
  notificationCount = 0;
  showComponents: boolean = false;
  extendedHeader: boolean = false;

  isBrowser: boolean = true;

  constructor(
    private globalService: GlobalService,
    private notificationService: NotificationsService,
    private navController: NavController,
    public router: Router,
    private location: Location,
    public auth: AuthService,
    public platform: Platform,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    if(this.isBrowser){
      this.showComponents = window.innerWidth > this.globalService.breakPointShowComponents;
      this.extendedHeader = window.innerWidth > this.globalService.breakPointMobile && window.innerWidth < this.globalService.breakPointShowComponents;
      window.addEventListener('resize', () => {
        this.showComponents = window.innerWidth > this.globalService.breakPointShowComponents;
        this.extendedHeader = window.innerWidth > this.globalService.breakPointMobile && window.innerWidth < this.globalService.breakPointShowComponents;
      });
    }else{
      this.showComponents = true;
      this.extendedHeader = true;
    }


    this.globalService.postactionsurveyEvent.subscribe(val => {
      if (val !== undefined && val !== null) {
        this.id = val?.id;
      } else {
        this.id = 0;
      }
    });

    this.notificationService.notificationEvent.subscribe(val => {
      this.notificationCount = val;
    });
  }


  async openSearchModal() {
    await this.navController.navigateForward(['/app/home/search/all']);
  }

  openPostModal() {
    this.navController.navigateForward(['/app/home/post-modal/' + this.type + '/' + this.id]);
  }

  openNotifications() {
    this.navController.navigateForward(['/app/home/notifications']);
  }

  openHome() {
    this.navController.navigateForward(['/app/home']);
  }

  openImpactSuite() {
    console.log(environment.name)
    switch (environment.name){
      case 'dev': {
        window.open('https://planeed-dev.wunu.dev/cms-login');
        break;
      }
      case 'stage': {
        window.open('https://planeed-stage.wunu.dev/cms-login');
        break;
      }
      case 'prod': {
        window.open('https://app.planeed.app/cms-login');
        break;
      }
      default: {
        break;
        }
    }

  }

  isStandardRoute(): boolean {
    return this.standardHeaderRoutes.includes(this.router.url);
  }

  isRegistrationRoute(): boolean {
    return this.registrationRoutes.includes(this.router.url);
  }

  back() {
    this.backDetect.emit(true);
    if (this.standardHeaderRoutes.includes(this.router.url)) {
      this.router.navigate(['/app/home'])
    } else {
      this.location.back()
    }
  }
}
