import {NgModule, PLATFORM_ID} from '@angular/core';
import {BrowserModule, Meta, provideClientHydration, withHttpTransferCacheOptions} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {HttpBackend, HttpClientModule, provideHttpClient, withFetch} from '@angular/common/http';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';

import {environment} from '../environments/environment';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ShowHidePasswordComponent} from './components/show-hide-password/show-hide-password.component';
import {IonicStorageModule} from '@ionic/storage-angular';
import {SwiperModule} from 'swiper/angular';
import {HeaderModule} from "./components/header/header.module";
import {LeafPageModule} from "./pages/leaf/leaf.module";
import {ActionPointPageModule} from "./pages/action-point/action-point.module";
import {ProfileWebComponent} from "./components/profile-web/profile-web.component";
import {MyTeamsOrganisationsComponent} from "./components/my-teams-organisations/my-teams-organisations.component";
import {TeamListModule} from "./components/team-list/team-list.module";
import {CompaniesListModule} from "./components/companies-list/companies-list.module";
import {LoginSideComponent} from "./components/login-side/login-side.component";
import {LoggedOutHeaderModule} from "./components/logged-out-header/logged-out-header.module";
import {isPlatformBrowser} from "@angular/common";
import {ShareModalComponent} from "./modal/share-modal/share-modal.component";

const linkToTranslations = `https://wunu.dev/planeed/i18n/${environment.name === 'demo' ? 'stage' : environment.name}/`;
export const createTranslateLoader = (http: HttpBackend, platformId: Object) => new MultiTranslateHttpLoader(
  http, [
    {prefix: isPlatformBrowser(platformId) ? './assets/i18n/' : linkToTranslations, suffix: '.json'},
    {
      prefix: linkToTranslations,
      suffix: '.json'
    }
  ]
);

@NgModule({
  declarations: [
    AppComponent,
    ShowHidePasswordComponent,
    ProfileWebComponent,
    MyTeamsOrganisationsComponent,
    LoginSideComponent,
    ShareModalComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpBackend, platformId: Object) => createTranslateLoader(http, platformId),
        deps: [HttpBackend, PLATFORM_ID]
      }
    }),
    IonicStorageModule.forRoot(),
    SwiperModule,
    HeaderModule,
    LeafPageModule,
    ActionPointPageModule,
    TeamListModule,
    CompaniesListModule,
    LoggedOutHeaderModule
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    provideClientHydration(
      withHttpTransferCacheOptions({
          includePostRequests: true,
        }
      )),
    provideHttpClient(withFetch()),
    Meta
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
