import {Component, Input, OnInit} from '@angular/core';
import {NavController} from '@ionic/angular';
import {CompanyModel} from 'src/app/models/company.model';
import {GlobalService} from "../../services/global/global.service";

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss'],
})
export class CompaniesListComponent implements OnInit {

  @Input() company: CompanyModel;
  @Input() impactScore = '';
  @Input('webComponent') webComponent: boolean = false;


  impactScoreVisible: number;
  bigScreen: boolean = false;

  constructor(
    private navController: NavController,
    private globalService: GlobalService
  ) {
  }

  ngOnInit() {
    this.bigScreen = window.innerWidth > this.globalService.breakPointBigScreen;
    window.addEventListener('resize', () => {
      this.bigScreen = window.innerWidth > this.globalService.breakPointBigScreen;
    });
    this.impactScoreVisible = this.company?.impactScore ? this.company.impactScore : 0;
    if (this.impactScoreVisible > 99.99 && !Number.isInteger(this.impactScoreVisible)) {
      this.impactScoreVisible = Math.floor(this.impactScoreVisible);
    }
  }

  openCompanyDetail(t: CompanyModel) {
    if (t.isAdmin) {
      this.navController.navigateForward(['/app/team/company/view-company/' + t.id]);
    } else {
      this.navController.navigateForward(['/app/team/company/other-company/' + t.id]);
    }
  }
}
