import {EventEmitter, Injectable} from '@angular/core';
import {Share} from "@capacitor/share";
import {ModalController, Platform} from "@ionic/angular";
import {ApiResponse, handleError} from "../utils";
import {getShareLink, postTrackPost} from "../variables";
import {catchError} from "rxjs/operators";
import {GlobalService} from "../global/global.service";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ShareModalComponent} from "../../modal/share-modal/share-modal.component";

@Injectable({
  providedIn: 'root'
})
export class ShareService {
  public simpleHeader = {
    'content-type': 'application/json',
    'x-deviceId': ''
  }

  public sharedLinkCounter = new EventEmitter<number>;

  private shareModal: any;
  private postId: string = '';

  constructor(
    private platform: Platform,
    private globalService: GlobalService,
    private modalController: ModalController,
    private translate: TranslateService,
    public http: HttpClient
  ) {
  }

  async share(id: string, title: string, description: string, shareUrl?: string) {
    this.postId = id;
    if (shareUrl && shareUrl !== '') {
      if (this.platform.is('capacitor')) {
        await Share.share({
          title: title,
          text: description,
          url: shareUrl,
          dialogTitle: this.translate.instant('lbl_share'),
        }).then(() => {
          this.apiTrackShare().subscribe({complete: () => {}});
          this.sharedLinkCounter.emit()
        });
      } else {
        await navigator.clipboard.writeText(shareUrl);
        await this.openModal(shareUrl, id);
      }
    } else {
      this.apiGetLink(id).subscribe({
        next: async (result) => {
          if (result && result.status) {
            if (this.platform.is('capacitor')) {
              await Share.share({
                text: title + '\n' + result.data.shareLink + '\n' + description,
                dialogTitle: this.translate.instant('lbl_share'),
              }).then(() => {
                this.apiTrackShare().subscribe({complete: () => {}});
                this.sharedLinkCounter.emit()
              });
            } else {
              await navigator.clipboard.writeText(result.data.shareLink);
              await this.openModal(result.data.shareLink, result.data.id);
            }
          } else {
            await this.globalService.presentToast(this.translate.instant('err_general'), 2000)
          }
        }
      });
    }
  }

  async openModal(link: string, postId: string) {
    this.postId = postId;
    this.shareModal = await this.modalController.create({
      component: ShareModalComponent,
      backdropDismiss: true,
      componentProps: {
        link: link,
        linkGotShared: this.sharedLinkCounter
      },
      mode: 'ios',
      id: 'small-modal',
    });

    await this.shareModal.present();
    await this.shareModal.onDidDismiss();
  }

  apiGetLink(id: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getShareLink + '/' + id, {headers: this.simpleHeader}).pipe(
      catchError(handleError)
    );
  }

  apiTrackShare() {
    const data = {
      postId: this.postId,
      referrer: this.globalService.lastRoute
    }
    return this.http.post<ApiResponse>(postTrackPost, data, {headers: this.simpleHeader}).pipe(
      catchError(handleError)
    );
  }
}
