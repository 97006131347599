import {Component, Input, OnInit} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {StatisticsComponent} from '../../modal/statistics/statistics.component';
import {ImpactScoreComponent} from '../../modal/impact-score/impact-score.component';


@Component({
  selector: 'app-leaf-impact-score',
  templateUrl: './leaf-impact-score.component.html',
  styleUrls: ['./leaf-impact-score.component.scss'],
})
export class LeafImpactScoreComponent implements OnInit {
  @Input() globalAP: number;
  @Input() globalValue: string;
  @Input() globalAdopts: number;
  @Input() globalAvg: string;
  @Input() myAP: number;
  @Input() myValue: string;
  @Input() myAdopts: number;
  @Input() label: string;
  @Input() icon: string;

  private statisticsModalElement?: any;
  private impactscoreModalElement?: any;

  constructor(
    private modalController: ModalController
  ) {
  }

  ngOnInit() {
  }

  async statisticsModal() {
    this.statisticsModalElement = await this.modalController.create({
      component: StatisticsComponent,
      backdropDismiss: true,
      mode: 'ios',
      id: 'generalModal'
    });
    await this.statisticsModalElement.present();

    await this.statisticsModalElement.onDidDismiss().then(res => {

    });
    return;
  }

  async impactscoremodal() {
    this.impactscoreModalElement = await this.modalController.create({
      component: ImpactScoreComponent,
      backdropDismiss: true,
      mode: 'ios',
      id: 'generalModal'
    });
    await this.impactscoreModalElement.present();

    await this.impactscoreModalElement.onDidDismiss().then(res => {

    });
    return;
  }

}
