import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {LeafPage} from './leaf.page';

import {LeafPageRoutingModule} from './leaf-routing.module';
import {HeaderModule} from '../../components/header/header.module';
import {TranslateModule} from '@ngx-translate/core';
import {SwiperModule} from 'swiper/angular';
import {ImpactScoreComponent} from '../../modal/impact-score/impact-score.component';
import {StatisticsComponent} from '../../modal/statistics/statistics.component';
import {CompaniesListModule} from 'src/app/components/companies-list/companies-list.module';
import {LeafImpactScoreModule} from '../../components/leaf-impact-score/leaf-impact-score.module';
import {ActionPointListModule} from '../../components/action-point-list/action-point-list.module';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        RouterModule.forChild([{path: '', component: LeafPage}]),
        LeafPageRoutingModule,
        HeaderModule,
        TranslateModule,
        SwiperModule,
        CompaniesListModule,
        LeafImpactScoreModule,
        ActionPointListModule
    ],
    exports: [
        LeafPage
    ],
    declarations: [
        LeafPage,
        ImpactScoreComponent,
        StatisticsComponent
    ]
})
export class LeafPageModule {
}
