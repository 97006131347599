import { Component, OnInit } from '@angular/core';
import {ModalController, NavController} from "@ionic/angular";
import {ApiResponse} from "../../services/utils";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth/auth.service";

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent  implements OnInit {
  loginForm: UntypedFormGroup;
  errorMessage: any;
  serverMessage = [];
  myDeviceToken: string;
  validationMessages = {
    email: [
      {type: 'required', message: 'err_email_is_required'},
      {
        type: 'pattern',
        message: 'err_enter_a_valid_email_address',
      },
    ],
    password: [
      {type: 'required', message: 'err_password_is_required'},
      {
        type: 'minlength',
        message: 'err_the_password_must_be_at_least_eight_characters_long',
      },
    ],
  };
  passwordInput;
  submitButton;

  constructor(
    private modalController: ModalController,
    public router: Router,
    private navController: NavController,
    private auth: AuthService
  ) {
    this.myDeviceToken = localStorage.getItem('deviceToken') || '';

    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl(
        '', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),])
      ),
      password: new UntypedFormControl(
        '', Validators.compose([Validators.minLength(8), Validators.required])
      )
    });
  }
  ngOnInit() {
    this.passwordInput = document.getElementById('passwordInput');
    this.submitButton = document.getElementById('submitButton');
  }

  onSubmit() {
    this.errorMessage = [];
    this.serverMessage = [];
    if (!this.loginForm.valid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    const request: any = this.loginForm.value;
    if (this.myDeviceToken) {
      request.deviceToken = this.myDeviceToken;
    }

    try {

      this.auth.login(request).subscribe((result: ApiResponse) => {
        if (result?.status) {
          const user = result.data;
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('selectLanguage', user.language);
          this.auth.userLoggedIn.next(user?.firstName);
          //  this.router.navigate(['/']);
          this.navController.navigateRoot(['/']);
          this.closeModal();
        } else {
          this.serverMessage = result.error;
        }

      });

    } catch (error) {
      console.error('Error trying login in', error);
    }
  }



  setPasswordFocus() {
    this.passwordInput.focus();
  }

  setSubmitFocus() {
    this.submitButton.focus();
  }
  closeModal() {
    this.modalController.dismiss();
  }

  async navigateTo(routeToNav: string){
    await this.router.navigate([routeToNav]);
    await this.modalController.dismiss();
  }

}
