import {EventEmitter, Inject, Injectable, PLATFORM_ID, signal} from '@angular/core';
import {UserModel} from '../../models/user.model';
import {Share} from '@capacitor/share';
import {LoadingController, ModalController, Platform, ToastController} from '@ionic/angular';
import {monthsNumbers} from '../utils';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Clipboard} from '@capacitor/clipboard';
import {Camera, CameraResultType} from '@capacitor/camera';
import {fileUploadSize} from 'src/app/services/utils';
import {LoginModalComponent} from "../../modal/login-modal/login-modal.component";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  loading: HTMLIonLoadingElement;
  selectedLang: string;
  private loginModal: any;

  public breakPointShowComponents: number = 1140;
  public breakPointMobile: number = 460;
  public breakPointBigScreen: number = 1920;
  public isLoadingWeb = signal(true);
  public homeContentEvent = new BehaviorSubject<any>(0);
  public backButtonEvent = new BehaviorSubject<any>(0);
  public languageEvent = new BehaviorSubject<any>('');
  public teamAPEvent = new BehaviorSubject<any>(0);
  public companyAPEvent = new BehaviorSubject<any>(0);

  public teamMyAPEvent = new BehaviorSubject<any>(0);
  public companyMyAPEvent = new BehaviorSubject<any>(0);

  public sdgsEvent = new BehaviorSubject<any>(0);
  public postactionsurveyEvent = new BehaviorSubject<any>(null);
  public postactionsurveySubject: Observable<any> = this.postactionsurveyEvent.asObservable();

  public homeScrollTop = new BehaviorSubject(null);

  public teamJoinedEvent = new BehaviorSubject<any>(null);

  public updateUser: EventEmitter<any> = new EventEmitter<any>();
  public isBrowser: boolean = true;
  public lastRoute: string = '';

  constructor(
    private loadingController: LoadingController,
    private translate: TranslateService,
    private toastController: ToastController,
    private platform: Platform,
    private modalController: ModalController,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.selectedLang = localStorage.getItem('selectLanguage') || 'de';
    } else {
      this.selectedLang = 'en';
    }
  }

  public get user(): UserModel {
    if (this.isBrowser) {
      const storageItem = localStorage.getItem('user');
      if (storageItem) {
        return JSON.parse(storageItem);
      }
    }
    return null;

  }

  public async presentToast(msg, time?) {
    const toast = await this.toastController.create({
      message: msg,
      duration: time ? time : 2000
    });
    await toast.present();
  }

  async writeToClipboard(text) {
    await Clipboard.write({
      // eslint-disable-next-line id-blacklist
      string: text
    });
    await this.presentToast('Text copy in clipboard');
  };

  public async inviteFriends(data) {
    try {
      if (this.platform.is('capacitor')) {
        await Share.share({
          title: data.inviteTitle,
          text: data.inviteText,
          url: data.inviteUrl,
          dialogTitle: this.translate.instant('lbl_share_with_buddies'),
        });
      } else {
        await navigator.clipboard.writeText(data.inviteTitle + '\n' + data.inviteText + '\n' + data.inviteUrl);
        await this.presentToast(this.translate.instant('lbl_copied_to_clipboard'), 2000)
      }
    } catch (error) {
      console.error('Error', error);
    }
  }

  timeSince(date) {
    if(this.isBrowser){
      this.selectedLang = localStorage.getItem('selectLanguage') || 'de';
    }else {
      this.selectedLang = 'en';
    }

    const date2 = new Date();
    const diff = date2.getTime() - (new Date(date).getTime());
    const seconds = Math.floor((diff) / 1000);

    let interval = seconds / 31536000;
    if (interval > 1) {
      const yearS = Math.floor(interval) === 1 ? this.translate.instant('lbl_year') : this.translate.instant('lbl_years');
      if (this.selectedLang === 'de') {
        return this.translate.instant('lbl_ago') + ' ' + Math.floor(interval) + ' ' + yearS;
      } else {
        return Math.floor(interval) + ' ' + yearS + ' ' + this.translate.instant('lbl_ago');
      }
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      const monthS = Math.floor(interval) === 1 ? this.translate.instant('lbl_month') : this.translate.instant('lbl_months');
      if (this.selectedLang === 'de') {
        return this.translate.instant('lbl_ago') + ' ' + Math.floor(interval) + ' ' + monthS;
      } else {
        return Math.floor(interval) + ' ' + monthS + ' ' + this.translate.instant('lbl_ago');
      }
    }

    interval = seconds / 604800;
    if (interval > 1) {
      const weekS = Math.floor(interval) === 1 ? this.translate.instant('lbl_week') : this.translate.instant('lbl_weeks');
      if (this.selectedLang === 'de') {
        return this.translate.instant('lbl_ago') + ' ' + Math.floor(interval) + ' ' + weekS;
      } else {
        return Math.floor(interval) + ' ' + weekS + ' ' + this.translate.instant('lbl_ago');
      }
    }

    interval = seconds / 86400;
    if (interval > 1) {
      const dayS = Math.floor(interval) === 1 ? this.translate.instant('lbl_day') : this.translate.instant('lbl_days');
      if (this.selectedLang === 'de') {
        return this.translate.instant('lbl_ago') + ' ' + Math.floor(interval) + ' ' + dayS;
      } else {
        return Math.floor(interval) + ' ' + dayS + ' ' + this.translate.instant('lbl_ago');
      }
    }
    interval = seconds / 3600;
    if (interval > 1) {
      const hourS = Math.floor(interval) === 1 ? this.translate.instant('lbl_hour') : this.translate.instant('lbl_hours');
      if (this.selectedLang === 'de') {
        return this.translate.instant('lbl_ago') + ' ' + Math.floor(interval) + ' ' + hourS;
      } else {
        return Math.floor(interval) + ' ' + hourS + ' ' + this.translate.instant('lbl_ago');
      }
    }
    interval = seconds / 60;
    if (interval > 1) {
      const minuteS = Math.floor(interval) === 1 ? this.translate.instant('lbl_minute') : this.translate.instant('lbl_minutes');
      if (this.selectedLang === 'de') {
        return this.translate.instant('lbl_ago') + ' ' + Math.floor(interval) + ' ' + minuteS;
      } else {
        return Math.floor(interval) + ' ' + minuteS + ' ' + this.translate.instant('lbl_ago');
      }
    }
    const secondS = Math.floor(interval) === 1 ? this.translate.instant('lbl_second') : this.translate.instant('lbl_seconds');
    if (this.selectedLang === 'de') {
      return this.translate.instant('lbl_ago') + ' ' + Math.floor(interval) + ' ' + secondS;
    } else {
      return Math.floor(interval) + ' ' + secondS + ' ' + this.translate.instant('lbl_ago');
    }

  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      /*duration: 1000*/
    });
    await this.loading.present();
  }

  async dismissLoading() {
    await this.loading.dismiss();
  }

  dateFormatted(dateFromIonDatetime) {
    const date = new Date(dateFromIonDatetime);
    return date.getDate() + '.' + monthsNumbers[date.getMonth()] + '.' + date.getFullYear();
  }

  dateTimeFormatted(dateFromIonDatetime) {
    const date = new Date(dateFromIonDatetime);
    const hours = date.getHours();

    return date.getDate() + '.' + monthsNumbers[date.getMonth()] + '.' +
      date.getFullYear() + ' ' + hours + ':' + date.getMinutes();
  }

  notificationTime(date) {
    const date2 = new Date();
    const diff = date2.getTime() - (new Date(date).getTime());
    const seconds = Math.floor((diff) / 1000);
    let interval: number = seconds / 86400;
    if (interval > 1) {
      if (this.selectedLang === 'de') {
        return this.translate.instant('lbl_ago') + ' ' + Math.floor(interval) + ' ' + this.translate.instant('lbl_days');
      } else {
        return Math.floor(interval) + ' ' + this.translate.instant('lbl_days') + ' ' + this.translate.instant('lbl_ago');
      }
    }

    interval = seconds / 3600;
    if (interval > 1) {
      if (this.selectedLang === 'de') {
        return this.translate.instant('lbl_ago') + ' ' + Math.floor(interval) + ' ' + this.translate.instant('lbl_hours');
      } else {
        return Math.floor(interval) + ' ' + this.translate.instant('lbl_hours') + ' ' + this.translate.instant('lbl_ago');
      }
    }
    interval = seconds / 60;
    if (interval > 1) {
      if (this.selectedLang === 'de') {
        return this.translate.instant('lbl_ago') + ' ' + Math.floor(interval) + ' ' + this.translate.instant('lbl_minutes');
      } else {
        return Math.floor(interval) + ' ' + this.translate.instant('lbl_minutes') + ' ' + this.translate.instant('lbl_ago');
      }
    }

    if (this.selectedLang === 'de') {
      return this.translate.instant('lbl_ago') + ' ' + Math.floor(interval) + ' ' + this.translate.instant('lbl_seconds');
    } else {
      return Math.floor(interval) + ' ' + this.translate.instant('lbl_seconds') + ' ' + this.translate.instant('lbl_ago');
    }
  }

  calculateImageSize(base64String) {
    let padding;
    if (base64String.endsWith('==')) {
      padding = 2;
    } else if (base64String.endsWith('=')) {
      padding = 1;
    } else {
      padding = 0;
    }

    const base64StringLength = base64String.length;
    return (base64StringLength / 4) * 3 - padding;
  }

  async takePhoto() {
    if (this.platform.is('capacitor')) {
      let imageImpactScoreSource: string;
      const errorMessage = [];
      const permission = await Camera.checkPermissions();
      if (permission.photos !== 'granted') {
        console.log('Permission', permission);
      }

      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.DataUrl
      });

      imageImpactScoreSource = image.dataUrl;
      const selectedFile = await fetch(imageImpactScoreSource).then(r => r.blob());
      if (this.calculateImageSize(imageImpactScoreSource) > fileUploadSize) {
        errorMessage.push(this.translate.instant('err_file_size_limit'));
        imageImpactScoreSource = '';
      }

      return {selectedFile, imageImpactScoreSource, errorMessage};
    } else {
      const errorMessage = [];
      let imageImpactScoreSource = '';
      const fileInput = document.getElementById('globalUploadImage');

      const handleFileChange = (e) => {
        return new Promise((resolve, reject) => {
          const file = e.target.files[0];
          const reader = new FileReader();
          reader.onload = (theFileData) => {
            if (typeof theFileData.target.result === "string") {
              imageImpactScoreSource = theFileData.target.result;
              resolve(imageImpactScoreSource);
            } else {
              reject(new Error('File data is not a string'));
            }
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };

      const waitForFileSelection = () => {
        return new Promise((resolve) => {
          fileInput.onchange = (e) => {
            handleFileChange(e)
              .then(resolve)
              .catch((error) => {
                errorMessage.push(error.message);
                console.error(error);
                resolve(null);
              });
          };
          fileInput.click();
        });
      };

      await waitForFileSelection();

      let selectedFile;
      if (imageImpactScoreSource) {
        selectedFile = await fetch(imageImpactScoreSource).then(r => r.blob());
        if (this.calculateImageSize(imageImpactScoreSource) > fileUploadSize) {
          errorMessage.push(this.translate.instant('err_file_size_limit'));
          imageImpactScoreSource = '';
        }
      }

      return {selectedFile, imageImpactScoreSource, errorMessage};
    }
  }


  async openLoginModal() {
    if (this.loginModal) {
      console.log('OPEN????', this.loginModal)
    }


    this.loginModal = await this.modalController.create({
      component: LoginModalComponent,
      backdropDismiss: true,
      mode: 'ios',
      id: 'loginToViewModal',
    });

    await this.loginModal.present();
    await this.loginModal.onDidDismiss();
  }

}
