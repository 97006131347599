import {EventEmitter, Injectable} from '@angular/core';
import {Share} from "@capacitor/share";
import {ModalController, Platform} from "@ionic/angular";
import {ApiResponse, handleError} from "../utils";
import {getShareLink, postTrackPost} from "../variables";
import {catchError} from "rxjs/operators";
import {GlobalService} from "../global/global.service";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ShareModalComponent} from "../../modal/share-modal/share-modal.component";
import {readBlobAsBase64} from "@capacitor/core/types/core-plugins";

@Injectable({
  providedIn: 'root'
})
export class ShareService {
  public simpleHeader = {
    'content-type': 'application/json',
    'x-deviceId': ''
  }

  public sharedLinkCounter = new EventEmitter<number>;

  private shareModal: any;
  private postId: string = '';

  constructor(
    private platform: Platform,
    private globalService: GlobalService,
    private modalController: ModalController,
    private translate: TranslateService,
    public http: HttpClient
  ) {
  }

  async share(id: string, title: string, description: string, shareUrl?: string, file?: string) {
    this.postId = id;
    if (file && file !== '') {

    }
    if (shareUrl && shareUrl !== '') {
      if (this.platform.is('capacitor')) {
        await Share.share({
          title: title,
          text: description,
          url: shareUrl,
          files: file && file !== '' ? [file] : [],
          dialogTitle: this.translate.instant('lbl_share'),
        }).then(() => {
          this.apiTrackShare().subscribe({
            complete: () => {
            }
          });
          this.sharedLinkCounter.emit()
        });
      } else {
        await this.shareNavigator(id, title, description, shareUrl, file);
      }
    } else {
      this.apiGetLink(id).subscribe({
        next: async (result) => {
          if (result && result.status) {
            if (this.platform.is('capacitor')) {
              await Share.share({
                text: title + '\n' + result.data.shareLink + '\n' + description,
                dialogTitle: this.translate.instant('lbl_share'),
              }).then(() => {
                this.apiTrackShare().subscribe({
                  complete: () => {
                  }
                });
                this.sharedLinkCounter.emit()
              });
            } else {
              await this.shareNavigator(result.data.id, title, description, result.data.shareLink, file);
            }
          } else {
            await this.globalService.presentToast(this.translate.instant('err_general'), 2000)
          }
        }
      });
    }
  }

  async openModal(link: string, postId: string) {
    this.postId = postId;
    this.shareModal = await this.modalController.create({
      component: ShareModalComponent,
      backdropDismiss: true,
      componentProps: {
        link: link,
        linkGotShared: this.sharedLinkCounter
      },
      mode: 'ios',
      id: 'small-modal',
    });

    await this.shareModal.present();
    await this.shareModal.onDidDismiss();
  }

  apiGetLink(id: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(getShareLink + '/' + id, {headers: this.simpleHeader}).pipe(
      catchError(handleError)
    );
  }

  apiTrackShare() {
    const data = {
      postId: this.postId,
      referrer: this.globalService.lastRoute
    }
    return this.http.post<ApiResponse>(postTrackPost, data, {headers: this.simpleHeader}).pipe(
      catchError(handleError)
    );
  }

  async shareNavigator(id: string, title: string, text: string, shareUrl?: string, fileUrl?: string) {
    const blobFile = async (): Promise<File> => {
      if (fileUrl && fileUrl !== '') {
        const defaultType = 'image/jpeg';
        const response = await fetch(fileUrl).then(r => r.blob());
        const fileName = response && response.type ? (title +'.'+ response.type.split('/')[1]).replace(' ', '') : 'planeed-post.jpeg'
        const file = new File([response], fileName, {
          type: response.type || defaultType,
        });
        return file;
      } else {
        return null;
      }
    }

    const data = async () => {
      const fileBlob: File = await blobFile();
      return {
        files: fileBlob? [fileBlob] : [],
        title: title,
        text: shareUrl + '\n' + text,
      }
    }


    if (navigator.canShare && navigator.canShare(await data())) {
      navigator.share(await data()).then(async () => {
        await navigator.clipboard.writeText(shareUrl);
        this.sharedLinkCounter.emit()
      }).catch((e) => {
        console.log('SHARE ERROR', e)
      });
    } else {
      await navigator.clipboard.writeText(shareUrl);
      await this.openModal(shareUrl, id);
    }
  }
}
