// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.read-more {
  color: #888;
}

.content-style {
  color: var(--ion-color-tertiary);
  font-size: 12px;
  margin-bottom: 0;
  -webkit-user-select: text;
  user-select: text;
}

.link {
  color: var(--ion-color-warning);
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;
}

.translate-cls {
  color: #888;
  text-align: right;
  cursor: pointer;
}

.bottom-buttons {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
