// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h3 {
  max-width: 182px;
  overflow-x: hidden;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}
h3:hover {
  cursor: pointer;
}

ion-card {
  margin: 8px 0 0;
  padding: 0;
  border-radius: 0;
  --ion-background-color: var(--planeed-color-white);
}

ion-list {
  padding: 0;
  margin: 0;
}

ion-row {
  margin: 0;
}
ion-row ion-icon {
  padding: 4px 0 0 0;
  width: 13px;
  height: 13px;
}

p {
  display: block;
  font-size: 12px;
}
p span:hover {
  cursor: pointer;
}

.line-14 {
  line-height: 14px;
}

.social-counter {
  margin-bottom: 10px;
  color: var(--ion-color-medium);
  padding: 4px 10px 0 4px;
}
.social-counter span {
  margin: 0 15px 0 5px;
  font-size: 13px;
}

.social-counter-small {
  margin-bottom: 10px;
  color: var(--ion-color-medium);
  padding: 5px 10px 0 1px;
}
.social-counter-small span {
  margin: 0 15px 0 5px;
  font-size: 10px;
}
.social-counter-small ion-icon {
  padding: 3px 0 0 0;
  height: 10px;
}

ion-item {
  --padding-start: 10px;
}

ion-item img {
  object-fit: cover;
  margin: 5px 10px 5px 0;
  border-radius: 10px;
}
ion-item img:hover {
  cursor: pointer;
}

.mobile-image {
  width: 90px;
  height: 90px;
}

@media only screen and (max-width: 1920px) {
  .web-image {
    height: 60px;
    width: 60px;
  }
}
@media only screen and (min-width: 1920px) {
  .web-image {
    height: 73px;
    width: 73px;
  }
}

ion-label a {
  color: var(--ion-color-secondary);
  text-decoration: underline;
  text-transform: capitalize;
}

ion-label {
  margin-top: 0;
  margin-bottom: 0;
}

.link {
  color: var(--ion-color-secondary);
  text-decoration: underline;
}

.myAdoptedAPIconMobile {
  margin-left: 65px;
}

.myAdoptedAPIconSmallWeb {
  margin-left: 38px;
}

.myAdoptedAPIconTallWeb {
  margin-left: 49px;
}

.myAdoptedAPIcon {
  text-align: right;
  margin-top: 8px;
  position: absolute;
  background: #fff;
  height: 22px;
  font-size: 11px;
  padding: 5px 5px;
  border-radius: 12px;
}

.myAdoptedAPIcon ion-icon {
  font-size: 12px;
}

.action-point-assigned-image {
  height: 25px !important;
  width: 25px !important;
  object-fit: contain;
  margin: 0 !important;
  border-radius: 12.5px !important;
  border: 1px solid var(--ion-color-medium);
  padding: 0 !important;
}

.action-point-assigned-image-box {
  width: 25px !important;
  display: inline-block;
  margin-left: -5px;
}

.action-point-assigned {
  margin-left: 2px;
}

.action-point-assigned-count {
  color: var(--ion-color-medium);
  font-size: 12px;
}

h3 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.impact-score-box ion-icon {
  background: var(--planeed-color-white);
  margin-top: -12px;
  position: absolute;
  margin-left: -12px;
  width: 26px;
  font-size: 15px;
}

.score-heading {
  text-align: center;
  color: var(--ion-color-medium);
}

.score-content-center2 {
  font-size: 16px;
  color: var(--ion-color-warning);
  text-align: center;
  padding-top: 32px;
}

.impact-score-box {
  border: 2px solid var(--ion-color-warning);
  width: 64px;
  height: 64px;
  text-align: center;
  font-size: 16px;
  position: relative;
  border-radius: 50%;
  color: var(--ion-color-warning);
  margin: 0 auto;
}

.score-content {
  position: relative;
  margin-top: -80px;
  padding-bottom: 16px;
}

.right-adopted-box {
  width: 74px;
  text-align: right;
}

.metrik-icon {
  margin-bottom: -2px;
  fill: var(--ion-color-secondary);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
