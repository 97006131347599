// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge-box {
  position: absolute;
  margin-top: -7px;
  margin-left: -9px;
  background: var(--ion-color-warning);
  --background: var(--ion-color-warning);
  color: var(--planeed-color-white);
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.notifications {
  margin-right: 5px;
  position: relative;
}

.webHeaderWidth .innerHeader {
  display: flex;
  flex-flow: row;
  align-items: center; /* vertically center the items */
  height: 56px;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .webHeaderWidth {
    width: 320px;
  }
}
@media only screen and (min-width: 1920px) and (max-width: 2560px) {
  .webHeaderWidth {
    width: 420px;
  }
}
@media only screen and (min-width: 2560px) {
  .webHeaderWidth {
    width: 520px;
  }
}

@media only screen and (max-width: 1920px) {
  .headerIcon {
    font-size: 18px;
  }
}
.headerLeft {
  margin-left: auto;
  margin-right: 0;
}

.headerIconBackground {
  background-color: var(--ion-color-secondary);
  height: 26px;
  width: 26px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.back-arrow {
  margin-right: 10px;
}
.back-arrow:hover {
  cursor: pointer;
}

.vertical-center-header {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1920px) {
  .height ion-toolbar {
    --min-height: 40px;
    height: 40px;
  }
}
@media only screen and (min-width: 1920px) {
  .height ion-toolbar {
    --min-height: 60px;
    height: 60px;
    display: flex;
    align-items: center;
  }
  .height .headerIcon {
    font-size: 26px;
  }
  .height ion-button {
    padding: 0 5px;
  }
  .height .header_icon {
    font-size: 24px;
  }
  .height .headerIconBackground {
    height: 36px;
    width: 36px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
