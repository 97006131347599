// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-split-pane {
  --border: none;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  ion-split-pane {
    --side-width: calc((100vw - 460px) / 2);
    --side-min-width: calc((100vw - 460px) / 2);
  }
}
@media only screen and (min-width: 1920px) and (max-width: 2560px) {
  ion-split-pane {
    --side-width: calc((100vw - 620px) / 2);
    --side-min-width: calc((100vw - 620px) / 2);
  }
}
@media only screen and (min-width: 2560px) {
  ion-split-pane {
    --side-width: calc((100vw - 740px) / 2);
    --side-min-width: calc((100vw - 740px) / 2);
  }
}

@media only screen and (min-width: 460px) and (max-width: 1140px) {
  .emptyFooter {
    width: 100%;
    height: 56px;
    background-color: var(--planeed-color-white);
    position: absolute;
    bottom: 0;
  }
}

.hide-side-content {
  display: none;
}

.fabButton-discover-more-home {
  width: 150px;
  height: 35px;
  line-height: 40px;
  position: fixed;
  bottom: 50px;
  left: 35px;
  --background: #FFFFFF;
  --border-radius: 50px;
  --border-width: 2px;
  --border-style: solid;
  --border-color: var(--ion-color-secondary);
  --ripple-color: #FFFFFF;
  --background-activated: #FFFF;
  --box-shadow: none;
}
@media only screen and (max-width: 1140px) {
  .fabButton-discover-more-home {
    display: none;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
