import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {ActionPointListComponent} from './action-point-list.component';
import {ReadMoreModule} from '../read-more/read-more.module';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule,
    ReadMoreModule, RouterModule],
  declarations: [ActionPointListComponent],
  exports: [ActionPointListComponent]
})
export class ActionPointListModule {
}
