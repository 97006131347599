// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-inner-container {
  background: var(--planeed-color-white);
  padding: 10px;
}
.action-inner-container h2 {
  text-align: center;
  color: var(--ion-color-secondary);
  margin-bottom: 30px;
}
.action-inner-container ion-segment-button {
  font-size: 11px;
  --background-checked: var(--ion-color-secondary);
  --color-checked: var(--planeed-color-white);
  --ion-background-color: var(--ion-color-secondary);
  --indicator-color: transparent !important;
  background: var(--planeed-color-white);
}
.action-inner-container .segment-button-checked {
  background: var(--ion-color-secondary);
  border-radius: 9px;
}
.action-inner-container ion-label {
  --margin: auto;
}

h2 {
  font-size: 17px;
}

.total-action-points {
  color: var(--ion-color-medium);
  padding: 0 10px;
}

.globle-icon {
  width: 13px;
  position: absolute;
  left: 21%;
  margin-top: 3px;
}

.content-not-found {
  text-align: center;
  padding-bottom: 35px;
}

.post-content {
  background: var(--planeed-color-white);
  min-height: calc(100vh - 230px);
}

.post-content-web {
  background: var(--planeed-color-white);
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.usedAsComponent {
  --border-radius: 20px;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .usedAsComponent {
    width: 320px;
    --padding-top: 20px;
    --padding-start: 20px;
  }
}
@media only screen and (min-width: 1920px) and (max-width: 2560px) {
  .usedAsComponent {
    width: 430px;
    --padding-top: 30px;
    --padding-start: 30px;
  }
}
@media only screen and (min-width: 2560px) {
  .usedAsComponent {
    width: 530px;
    --padding-top: 30px;
    --padding-start: 30px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
