import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {NavController, Platform} from '@ionic/angular';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth/auth.service';
import {GlobalService} from "../services/global/global.service";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  loginIsOpen: boolean = false;
  isBrowser: boolean;

  constructor(
    private auth: AuthService,
    private router: Router,
    private navController: NavController,
    private platform: Platform,
    private globalService: GlobalService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.isBrowser) {
      if (
        !this.auth.isAuthenticated && state.url && !state.url.includes('app/home/view-post/post/') &&
        !state.url.includes('app/home/view-post/action_point') &&
        this.platform.is('capacitor')) {
        // user installing add first time then move to register page other it move login page
        const appInstall = localStorage.getItem('app_install') || '';
        if (appInstall !== '') {
          return this.navController.navigateRoot(['/login']);
        } else {
          return this.navController.navigateRoot(['/register']);
        }
      } else if (
        !this.auth.isAuthenticated &&
        (
          state.url.includes('/app/profile') ||
          state.url.includes('app/home/other-profile') ||
          state.url === '/app/team' ||
          state.url.includes('app/home/post-modal') ||
          state.url.includes('app/home/notificat') ||
          state.url.includes('search')
        ) &&
        !this.platform.is('capacitor')
      ) {
        if (!this.loginIsOpen && this.router.url !== '/') {
          this.loginIsOpen = true;
          this.globalService.openLoginModal().then(() => {
            this.loginIsOpen = false;
          });
        } else if (this.router.url === '/') {
          return this.navController.navigateRoot(['/app/home']);
        }
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
}
