// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form {
  padding: 27px 20px;
}
.login-form .form-group {
  margin-bottom: 35px;
}
.login-form .form-group ion-item {
  --border-width: 0;
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0px;
}
.login-form .form-group ion-item div {
  width: 100%;
}

.logo-image {
  border: 0;
  width: 50% !important;
  display: block;
}
@media only screen and (max-height: 700px) {
  .logo-image {
    margin: 5px auto 35px auto;
  }
}
@media only screen and (min-height: 700px) {
  .logo-image {
    margin: 75px auto;
  }
}

.center-title {
  font-size: 25px;
  font-weight: lighter;
  line-height: 49px;
  color: var(--ion-color-text-color);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-top: -15px;
}
@media only screen and (max-height: 700px) {
  .center-title {
    font-size: 20px;
  }
}

.ng-pristine.ng-invalid.ng-touched {
  border-color: var(--ion-color-danger);
}

.item-has-focus icon-label {
  transform: translate3d(0, 0, 0) scale(0.8);
}

.placeholder {
  font-size: 15px;
}

ion-input {
  --padding-top: 14px;
  --padding-bottom: 14px;
  --padding-start: 20px !important;
  --padding-end: 20px;
  border-radius: 5px !important;
  background-color: var(--planeed-color-white) !important;
  font-size: 12px !important;
  z-index: 1 !important;
  width: 100%;
  border: 1px solid var(--ion-color-tertiary-opacity);
}

.close-button-div {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0px 20px;
}
.close-button-div .close-modal-button {
  align-self: end;
  height: 30px;
  width: 30px;
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0px;
  --padding-top: 0px;
}
.close-button-div .close-modal-button .close-modal-icon {
  height: 30px;
  width: 30px;
}

.forgot-password {
  margin-bottom: 35px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
