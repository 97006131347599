import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

import {HeaderComponent} from './header.component';
import {PostModalComponent} from '../../modal/post-modal/post-modal.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule],
  declarations: [HeaderComponent, PostModalComponent],
  exports: [HeaderComponent]
})
export class HeaderModule {
}
