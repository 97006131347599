import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {UserModel} from '../../models/user.model';
import {forgotPasswordUrl, loginUrl, logoutUrl, registerUrl, verifyEmail, verifyUsername} from '../variables';
import {ApiResponse, handleError} from '../utils';
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  url: string;

  isBrowser: boolean = true;
  httpOptions: any;
  headers = {'content-type': 'application/json'};
  userLoggedIn = new BehaviorSubject('0');

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isAuthenticated) {
      this.userLoggedIn.next(this.user?.firstName);
    } else {
      this.userLoggedIn.next('0');
    }
  }

  get isAuthenticated(): boolean {
    if (this.isBrowser) {
      const storageItem = localStorage.getItem('user');
      return !!storageItem;
    } else {
      return false;
    }

  }

  get simpleHeader() {
    return {
      'content-type': 'application/json'
    };
  }

  get getUserStatus() {
    return this.userLoggedIn;
  }

  get jsonheader() {
    if (this.isBrowser) {
      return this.user && this.user.token ?
        {
          'content-type': 'application/json',
          authorization: 'Bearer ' + this.user?.token
        } :
        {
          'content-type': 'application/json'
        }
    } else {
      return {
        'content-type': 'application/json'
      }
    }

  }

  get multiPartHeader() {
    if(this.isBrowser && this.user && this.user.token){
      return {
        authorization: 'Bearer ' + this.user.token
      };
    }else{
      return {
        'content-type': 'application/json'
      }
    }
  }

  public get user(): UserModel {
    if(this.isBrowser){
      const storageItem = localStorage.getItem('user');
      if (storageItem) {
        return JSON.parse(storageItem);
      }
    }
    return null;
  }

  login(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(loginUrl, data, {headers: this.headers}).pipe(
      catchError(handleError),
      map((result: ApiResponse) => {
        const listing = new ApiResponse();
        Object.assign(listing, result);
        if (result.status) {
          this.userLoggedIn.next(result.data.firstName);
        }
        return listing;
      })
    );
  }

  register(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(registerUrl, data, {headers: this.headers}).pipe(
      catchError(handleError),
      map((result: ApiResponse) => {
        const listing = new ApiResponse();
        Object.assign(listing, result);
        if (result.status) {
          this.userLoggedIn.next(result.data.firstName);
        }
        return listing;
      })
    );
  }

  forgot(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(forgotPasswordUrl, data, {headers: this.headers}).pipe(
      catchError(handleError)
    );
  }

  verifyEmail(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(verifyEmail, data, {headers: this.headers}).pipe(
      catchError(handleError),
    );
  }

  verifyUsername(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(verifyUsername, data, {headers: this.headers}).pipe(
      catchError(handleError),
    );
  }

  logout(data) {
    return this.http.post(logoutUrl, data, {headers: this.jsonheader}).pipe(
      catchError(handleError),
    );
  }
}
