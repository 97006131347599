import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {ShareService} from "../../services/share/share.service";

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss'],
})
export class ShareModalComponent implements OnInit {
  @Input('link') link: string = '';
  @Input('linkGotShared') linkGotShared = new EventEmitter<number>;

  constructor(
    private modalController: ModalController,
    private shareService: ShareService
  ) {
  }

  ngOnInit() {
  }

  async closeModal(): Promise<void> {
    await this.modalController.dismiss(null, 'cancel');
  }

  openSocialMedia(media: string){
    const links = {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      tiktok: 'https://www.tiktok.com',
      linkedIn: 'https://www.linkedIn.com',
      whatsapp: 'https://wa.me/?text='+ encodeURI(this.link)
    }

    this.shareService.apiTrackShare().subscribe({complete: () => {}});
    this.linkGotShared.emit(1);
    if(media === 'whatsapp'){
      window.open(links[media])
    }else {
      window.open(links[media], "_blank")
    }

  }


}
