import {Component, Input, OnInit} from '@angular/core';
import {NavController} from '@ionic/angular';
import {TeamModel} from 'src/app/models/team.model';
import {GlobalService} from "../../services/global/global.service";

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.scss'],
})
export class TeamListComponent implements OnInit {

  @Input() team: TeamModel;
  @Input() impactScore = '';
  @Input('webComponent') webComponent: boolean = false;

  impactScoreVisible: number;
  bigScreen: boolean = false;

  constructor(
    private navController: NavController,
    private globalService: GlobalService
  ) {
  }

  ngOnInit() {
    this.bigScreen = window.innerWidth > this.globalService.breakPointBigScreen;
    window.addEventListener('resize', () => {
      this.bigScreen = window.innerWidth > this.globalService.breakPointBigScreen;
    });
    this.impactScoreVisible = this.team?.impactScore ? parseInt(this.team?.impactScore, 10) : 0;
    if (this.impactScoreVisible > 99.99 && !Number.isInteger(this.impactScoreVisible)) {
      this.impactScoreVisible = Math.floor(this.impactScoreVisible);
    }
    this.filterTeamDescription();
  }

  async openTeamDetail(t: TeamModel) {
    if (t.isAdmin) {
      await this.navController.navigateForward(['/app/team/view-team/' + t.id]);
    } else {
      await this.navController.navigateForward(['/app/team/other-team/' + t.id]);
    }
  }

  readMoreFun(data: any) {
  }

  filterTeamDescription() {
    if (this.team.description !== null && this.team.description.length > 36) {
      const wordsOfTeamDescription = this.team.description.split(' ');
      const nrOfWords = wordsOfTeamDescription.length;
      let counterOfChars = 0;
      let teamDesc = '';
      for (let i = 0; i < nrOfWords; i++) {
        if (wordsOfTeamDescription[i].length + counterOfChars < 36) {
          counterOfChars += wordsOfTeamDescription[i].length;
          teamDesc += wordsOfTeamDescription[i] + ' ';
        } else {
          if (teamDesc.length === 0) {
            this.team.description = this.team.description.slice(0, 36) + ' ...';
            break;
          } else {
            teamDesc += '...';
            this.team.description = teamDesc;
            break;
          }
        }
      }
    }
  }
}
