import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AlertController, NavController, Platform, PopoverController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {CompanyModel} from 'src/app/models/company.model';
import {PostModel} from 'src/app/models/post.model';
import {TeamModel} from 'src/app/models/team.model';
import {UserModel} from 'src/app/models/user.model';
import {AuthService} from 'src/app/services/auth/auth.service';
import {CompanyService} from 'src/app/services/company/company.service';
import {GlobalService} from 'src/app/services/global/global.service';
import {TeamService} from 'src/app/services/team/team.service';
import {ApiResponse} from 'src/app/services/utils';

@Component({
  selector: 'app-action-point-list',
  templateUrl: './action-point-list.component.html',
  styleUrls: ['./action-point-list.component.scss'],
})
export class ActionPointListComponent implements OnInit {

  @Input() item: PostModel;
  @Input() type: string;
  @Input() teamId: any;
  @Input() companyId: any;
  @Input() showMetrics: boolean;
  @Output() impactScoreChanged: EventEmitter<number> = new EventEmitter();
  @Input('usedInComponent') usedInComponent: boolean = false;

  teamActionPoint: boolean;
  teamCompanyActionPoint: boolean;
  user: UserModel;
  companyOwner = false;
  company: CompanyModel;
  team: TeamModel;
  teamOwner = false;
  impactScoreVisible: number;
  iconMetric: string;
  isWeb: boolean = false;
  isSmallScreen: boolean = false;

  constructor(
    private navController: NavController,
    private auth: AuthService,
    private teamService: TeamService,
    public globalService: GlobalService,
    public translate: TranslateService,
    private alertController: AlertController,
    private popoverCtrl: PopoverController,
    private companyService: CompanyService,
    private platform: Platform
  ) {

  }

  async ngOnInit() {
    this.isWeb = !this.platform.is('capacitor');
    this.isSmallScreen = window.innerWidth < this.globalService.breakPointBigScreen;
    window.addEventListener('resize', () => {
      this.isSmallScreen = window.innerWidth < this.globalService.breakPointBigScreen;
    });
    this.user = this.auth.user;
    this.impactScoreVisible = this.item?.impactScore ? this.item?.impactScore : 0;
    if (this.impactScoreVisible > 99.99 && !Number.isInteger(this.impactScoreVisible)) {
      this.impactScoreVisible = Math.floor(this.impactScoreVisible);
    }

    if (this.item?.assignedTeams?.length > 0) {
      let flag = 0;
      this.item.assignedTeams.forEach(tm => {
        if (Number(tm.id) === Number(this.teamId)) {
          flag = 1;
        }
        tm?.assignedBy.forEach(element => {
          if (this.user && this.user.id && this.user.id === element.id && flag === 1) {
            this.teamActionPoint = true;
          }
        });
      });
    }

    if (this.item?.assignedCompanies?.length > 0) {
      let flag = 0;
      this.item.assignedCompanies.forEach((tm: any) => {
        if (Number(tm.id) === Number(this.companyId)) {
          flag = 1;
        }
        tm?.assignedBy.forEach(element => {
          if (this.user && this.user.id && this.user.id === element.id && flag === 1) {
            this.teamCompanyActionPoint = true;
          }
        });
      });
    }

    // if (this.item?.team?.id && Number(this.item?.team.id) === Number(this.teamId)) {
    //   this.alreadyTaken = true;
    // } else if (this.item?.team?.id) {
    //   this.alreadyTaken = false;
    // }

    if (this.companyId) {
      this.loadCompany(this.companyId);
    }

    if (this.teamId) {
      this.loadTeam(this.teamId);
    }

    if (this.item.metrics !== undefined) {
      if (this.item.metrics[0].metric.icon !== '') {
        this.iconMetric = this.item.metrics[0].metric.icon;
      }
    } else {
      this.iconMetric = '';
    }
  }

  checkIsAssignedCompany(id) {
    return id === Number(this.companyId);
  }

  checkIsAssignedTeam(id) {
    return id === Number(this.teamId);
  }

  loadTeam(id) {
    this.teamService.apiViewTeam(id).subscribe((result: ApiResponse) => {
      if (result.status) {
        this.team = result?.data;
        if (this.user && this.user.id === this.team.createdBy.id ) {
          this.teamOwner = true;
        }
      }
    });
  }

  loadCompany(id) {
    this.companyService.apiViewCompany(id).subscribe((result: ApiResponse) => {
      if (result.status) {
        this.company = result?.data;
        if (this.user && this.user.id === this.company.createdBy.id ) {
          this.companyOwner = true;
        }
      }
    });
  }

  companyEmployee() {
    return this.user && this.user?.company?.id === Number(this.companyId);
  }

  openActionPoint(item) {
    this.navController.navigateForward(['/app/home/view-post/action_point/' + item.id + '/detail']);
  }

  async openOtherProfile(id) {
    if (!this.item.createdBy.isDeleted) {
      await this.navController.navigateForward(['/app/home/other-profile/' + id]);
    }
  }

  toggleActionPoint() {
    if (this.teamActionPoint) {
      this.removeActionPoint();
    } else {
      this.assignActionPoint();
    }
  }

  toggleCompanyActionPoint() {
    if (this.teamCompanyActionPoint) {
      this.removeCompanyActionPoint();
    } else {
      this.assignCompanyActionPoint();
    }
  }

  assignCompanyActionPoint() {
    const data = {
      postId: this.item.id,
      companyId: this.companyId
    };

    this.companyService.apiAssignCompanyActionPoint(data).subscribe((result: any) => {
      if (result.status) {
        this.impactScoreChanged.emit(this.impactScoreVisible);
        this.teamCompanyActionPoint = !this.teamCompanyActionPoint;
        this.globalService.presentToast(result.message);
        this.globalService.companyAPEvent.next(this.companyId);
      } else {
        this.teamCompanyActionPoint = false;
        this.globalService.presentToast(result.error[0]);
      }
    });
  }

  removeCompanyActionPoint() {
    const data = {
      postId: this.item.id,
      companyId: this.companyId
    };

    this.companyService.apiRemoveCompanyActionPoint(data).subscribe((result: any) => {
      if (result.status) {
        this.impactScoreChanged.emit(this.impactScoreVisible * -1);
        this.teamCompanyActionPoint = !this.teamCompanyActionPoint;
        this.globalService.presentToast(result.message);
        this.globalService.companyAPEvent.next(this.companyId);
      }
    });
  }

  assignActionPoint() {
    const data = {
      postId: this.item.id,
      teamId: this.teamId
    };

    this.teamService.apiAssignActionPoint(data).subscribe((result: any) => {
      if (result.status) {
        this.teamActionPoint = !this.teamActionPoint;
        this.globalService.presentToast(result.message);
        this.globalService.teamAPEvent.next(this.teamId);
      } else {
        this.teamActionPoint = false;
        this.globalService.presentToast(result.error[0]);
      }
    });
  }

  removeActionPoint() {
    const data = {
      postId: this.item.id,
      teamId: this.teamId
    };

    this.teamService.apiRemoveActionPoint(data).subscribe((result: any) => {
      if (result.status) {
        this.teamActionPoint = !this.teamActionPoint;
        this.globalService.presentToast(result.message);
        this.globalService.teamAPEvent.next(this.teamId);
      }
    });
  }

  getActionPointAssignedCount() {
    let totalCount = 0;
    let val: string;
    this.item.assignedTeams?.forEach((assignedTeams: any) => {
      if (assignedTeams.id === Number(this.teamId)) {
        assignedTeams.assignedBy?.forEach(() => {
          totalCount++;
        });
      }
    });

    if (totalCount > 3) {
      val = ' + ' + (totalCount - 3) + ' ' + this.translate.instant('txt_more');
    } else {
      val = '';
    }
    return val;
  }

  getCompanyActionPointAssignedCount() {
    let totalCount = 0;
    let val: string;
    this.item.assignedCompanies?.forEach((assignedCompanies: any) => {
      if (assignedCompanies.id === Number(this.companyId)) {
        assignedCompanies.assignedBy?.forEach(() => {
          totalCount++;
        });
      }
    });

    if (totalCount > 3) {
      val = ' + ' + (totalCount - 3) + ' ' + this.translate.instant('txt_more');
    } else {
      val = '';
    }
    return val;
  }

  async deleteActionPoint(item) {
    this.popoverCtrl.dismiss();
    const alert = await this.alertController.create({
      header: this.translate.instant('lbl_remove_action_point'),
      cssClass: 'remove-action-point-alert',
      message: this.translate.instant('txt_do_you_really_want_to_remove_this_action_point'),
      buttons: [
        {
          text: this.translate.instant('lbl_no'),
          role: 'cancel',
          cssClass: 'cancel-button',
          id: 'cancel-button',
          handler: () => {

          }
        },
        {
          text: this.translate.instant('lbl_yes'),
          id: 'confirm-button',
          cssClass: 'confirm-button',
          handler: () => {
            this.removeActionPoint();
            this.globalService.teamMyAPEvent.next(this.teamId);

          }
        }
      ]
    });

    await alert.present();
  }

  async deleteCompanyActionPoint(item) {
    this.popoverCtrl.dismiss();
    const alert = await this.alertController.create({
      header: this.translate.instant('lbl_remove_action_point'),
      cssClass: 'remove-action-point-alert',
      message: this.translate.instant('txt_do_you_really_want_to_remove_this_action_point'),
      buttons: [
        {
          text: this.translate.instant('lbl_no'),
          role: 'cancel',
          cssClass: 'cancel-button',
          id: 'cancel-button',
          handler: () => {

          }
        },
        {
          text: this.translate.instant('lbl_yes'),
          id: 'confirm-button',
          cssClass: 'confirm-button',
          handler: () => {
            this.removeCompanyActionPoint();
            this.globalService.companyMyAPEvent.next(this.companyId);
          }
        }
      ]
    });

    await alert.present();
  }

  checkAPAssignedByCompany(id, item) {
    let flag = false;
    item.assignedCompanies?.forEach((assignedCompanies: any) => {
      if (assignedCompanies.id === Number(this.companyId)) {
        assignedCompanies.assignedBy?.forEach(assignedBy => {
          if (assignedBy.id === id) {
            flag = true;
          }
        });
      }
    });
    return flag;
  }

  checkAPAssignedByTeam(id, item) {
    let flag = false;
    item.assignedTeams?.forEach((assignedTeam: any) => {
      if (assignedTeam.id === Number(this.teamId)) {
        assignedTeam.assignedBy?.forEach(assignedBy => {
          if (assignedBy.id === id) {
            flag = true;
          }
        });
      }
    });
    return flag;
  }

  openCompany(c) {
    if (c?.isAdmin) {
      this.navController.navigateForward(['/app/team/company/view-company/' + c.id]);
    } else {
      this.navController.navigateForward(['/app/team/company/other-company/' + c.id]);
    }
  }

  fixedNumber(num) {
    const fixedNum = Number(num).toFixed(0);
    return fixedNum.toString();
  }
}
