import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompaniesListComponent} from './companies-list.component';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';

@NgModule({
  declarations: [CompaniesListComponent],
  imports: [
    CommonModule,
    TranslateModule,
    IonicModule
  ],
  exports: [
    CompaniesListComponent
  ]
})
export class CompaniesListModule {
}
