// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content {
  color: var(--ion-color-secondary);
}
.main-content h2 {
  color: var(--ion-color-text-color);
  font-size: 17px;
}
.main-content span {
  font-size: 12px;
}

.h2-center {
  text-align: center;
  color: var(--ion-color-secondary);
  margin-left: 25px;
  margin-top: 10px;
}
.h2-center ion-icon {
  position: absolute;
  margin-top: 1px;
  margin-left: -22px;
}

.help-icon {
  display: inline-block;
  background: var(--ion-color-tertiary-tint);
  color: var(--planeed-color-white);
  width: 15px;
  height: 15px;
  border-radius: 13px;
  font-size: 11px;
  padding-top: 1px;
  top: -2px;
  /*position: relative;*/
  margin-left: 4px;
  margin-bottom: 2px;
  cursor: pointer;
}

.content {
  padding: 10px;
}
.content h3 {
  padding: 0;
  margin: 0;
  color: var(--ion-color-medium);
  font-size: 16px;
}

.action-point-box {
  border: 2px solid var(--ion-color-medium);
  width: 80px;
  height: 80px;
  text-align: center;
  font-size: 22px;
  position: relative;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 15px;
}
.action-point-box ion-icon {
  background: var(--planeed-color-white);
  margin-top: -14px;
  position: absolute;
  margin-left: -13px;
  width: 28px;
}

.impact-score-box {
  border: 2px solid var(--ion-color-warning);
  width: 100px;
  height: 100px;
  text-align: center;
  font-size: 25px;
  position: relative;
  border-radius: 50%;
  color: var(--ion-color-warning);
  margin: 0 auto;
}
.impact-score-box ion-icon {
  background: var(--planeed-color-white);
  margin-top: -10px;
  position: absolute;
  margin-left: -17px;
  width: 36px;
}

.member-box {
  border: 2px solid var(--ion-color-medium);
  width: 80px;
  height: 80px;
  text-align: center;
  font-size: 25px;
  position: relative;
  border-radius: 50%;
  margin: 15px auto 0;
}
.member-box ion-icon {
  background: var(--planeed-color-white);
  margin-top: -14px;
  position: absolute;
  margin-left: -13px;
  width: 28px;
}

.score-heading {
  text-align: center;
  color: var(--ion-color-medium);
}

.score-content {
  position: relative;
  margin-top: -70px;
  padding-bottom: 21px;
  color: var(--ion-color-medium);
}

.score-content-center {
  font-size: 15px;
  text-align: center;
  position: relative;
  margin-top: 5px;
}

.score-content-center2 {
  font-size: 17px;
  color: var(--ion-color-warning);
  text-align: center;
}

.impact_score_txt {
  color: var(--ion-color-warning);
}

.inner-impact-score {
  margin-top: -15px;
  font-size: 17px;
}

p {
  margin: 0 0;
}

hr {
  padding: 0;
  width: 80px;
  margin: 0 auto;
  background: var(--ion-color-warning);
}

.modal-wrapper, .modal-shadow {
  background: transparent;
}

.fillOrange {
  fill: #FF8E14;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
