import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ApiResponse} from "../../services/utils";
import {UserModel} from "../../models/user.model";
import {UserService} from "../../services/user/user.service";
import {GlobalService} from "../../services/global/global.service";
import {NavController} from "@ionic/angular";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-profile-web',
  templateUrl: './profile-web.component.html',
  styleUrls: ['./profile-web.component.scss'],
})
export class ProfileWebComponent  implements OnInit {

  user: UserModel;
  isLoading: boolean = false;
  isBrowser: boolean = false;
  constructor(
    private userService: UserService,
    private navController: NavController,
    private globalService: GlobalService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    if(this.isBrowser){
      this.globalService.updateUser.subscribe({
        next: () => {
          this.user = JSON.parse(localStorage.getItem('user'));
        }
      })
    }
    this.loadUser();
  }

  loadUser() {
    this.isLoading = true;
    this.userService.apiUserProfile().subscribe((result: ApiResponse) => {
      if (result.status) {
        this.user = result.data;
      }
      this.isLoading = false;
    });
  }

  async openCompany(c) {
    if (c?.isAdmin) {
      await this.navController.navigateForward(['/app/team/company/view-company/' + c.id]);
    } else {
      await this.navController.navigateForward(['/app/team/company/other-company/' + c.id]);
    }
  }

  async openProfile(){
    await this.navController.navigateForward(['/app/profile'])
  }
}
