// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-side-comp {
  margin-left: auto;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--planeed-color-white);
  /*  img{
      border: 0;
      width: 50% !important;
      @media only screen and (min-width: \$small-screen) and (max-width: \$medium-screen) {
        margin: 25px auto;
      }
      @media only screen and (min-width: \$medium-screen) and (max-width: \$big-screen) {
        margin: 35px auto;
      }
      @media only screen and (min-width: \$big-screen) {
        margin: 45px auto;
      }
      display: block;
    }*/
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .login-side-comp {
    margin-right: 20px;
    margin-top: 20px;
    width: 300px;
  }
}
@media only screen and (min-width: 1920px) and (max-width: 2560px) {
  .login-side-comp {
    margin-right: 30px;
    margin-top: 30px;
    width: 400px;
  }
}
@media only screen and (min-width: 2560px) {
  .login-side-comp {
    margin-right: 30px;
    margin-top: 30px;
    width: 500px;
  }
}
.login-side-comp .profile-header-img {
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  cursor: pointer;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .login-side-comp .profile-header-img {
    height: 100px;
  }
}
@media only screen and (min-width: 1920px) {
  .login-side-comp .profile-header-img {
    height: 150px;
  }
}
.login-side-comp .photo-box {
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  cursor: pointer;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .login-side-comp .photo-box {
    height: 100px;
    margin-top: -75px;
  }
}
@media only screen and (min-width: 1920px) {
  .login-side-comp .photo-box {
    height: 150px;
    margin-top: -112px;
  }
}
.login-side-comp .photo-box img {
  border-radius: 50%;
  object-fit: cover;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .login-side-comp .photo-box img {
    width: 100px;
    height: 100px;
  }
}
@media only screen and (min-width: 1920px) {
  .login-side-comp .photo-box img {
    width: 150px;
    height: 150px;
  }
}
.login-side-comp h2 {
  color: var(--ion-color-secondary);
  text-align: center;
  width: 100%;
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .login-side-comp h2 {
    font-size: 16px;
    padding: 0 20px;
  }
}
@media only screen and (min-width: 1920px) and (max-width: 2560px) {
  .login-side-comp h2 {
    font-size: 18px;
    padding: 0 25px;
  }
}
@media only screen and (min-width: 2560px) {
  .login-side-comp h2 {
    font-size: 20px;
    padding: 0 30px;
  }
}
@media only screen and (min-width: 460px) and (max-width: 1920px) {
  .login-side-comp .btn {
    margin: 20px;
  }
}
@media only screen and (min-width: 1920px) and (max-width: 2560px) {
  .login-side-comp .btn {
    margin: 25px;
  }
}
@media only screen and (min-width: 2560px) {
  .login-side-comp .btn {
    margin: 30px;
  }
}
.login-side-comp .btn .secondary-btn {
  margin-bottom: 20px;
}
.login-side-comp .body-text {
  padding: 0 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
