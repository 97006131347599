import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoggedOutHeaderComponent} from './logged-out-header.component';
import {IonicModule} from '@ionic/angular';


@NgModule({
  declarations: [LoggedOutHeaderComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [LoggedOutHeaderComponent]
})
export class LoggedOutHeaderModule {
}
